import '../../../styles/detail.css';
import React, { useState } from 'react';
import EventCreateForm from '../../../components/events/eventCreateForm';
import { useNavigate } from 'react-router-dom';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
import { validateEventData, validateQuestion } from './user-dashboard-event-create-utility';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';
import UserStatusModel from '../../../statuscodemanage/user-status-model';
import { removeLocalStorage } from '../../../utils/removeLocalstorage';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');


const EventCreatePage = () => {
    const authKey = localStorage.getItem('authKey')
    const [loading, setLoading] = useState(false)
    const [eventData, setEventData] = useState({
        eventName: '',
        eventDescription: '',
        eventImage: '',
        eventTime: '',
        eventLocation: '',
        eventMaxBookings: '',
        ticketPrice: '',
        eventLinks: new Array(6).fill(''), // Assuming 6 links
        formQuestions: new Array(10).fill(''), // Assuming 10 questions
    });
    const [coordinates, setCoordinates] = React.useState({
        longitude: '',
        latitude: '',
        placeId: '',
        label:''
    })
    const [featured, setFeatured] = useState(false)
    const [imageFile, setImageFile] = useState(null); // Initialize imageFile state
    const navigate = useNavigate();
    const [response, setResponse] = useState({})

    const handleImageDrop = (file) => {
        setImageFile(file); // Set the file to the imageFile state
        const reader = new FileReader();
        reader.onloadend = () => {
            setEventData(prevState => ({
                ...prevState,
                eventImage: file
            }));
        };
        reader.readAsDataURL(file);
    };

    // const handleQuestionChange = (index, value) => {
    //     const updatedQuestions = [...eventData.formQuestions];
    //     updatedQuestions[index] = value;
    //     setEventData(prevState => ({
    //         ...prevState,
    //         formQuestions: updatedQuestions
    //     }));
    // };
    // This function remains the same as you defined
    const handleLinkChange = (index, value) => {
        const updatedLinks = [...eventData.eventLinks];
        updatedLinks[index] = value;
        setEventData(prevState => ({
            ...prevState,
            eventLinks: prevState.eventLinks.map((link, i) => i === index ? value : link)
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventData({ ...eventData, [name]: value });
    };

    const isValidSpotifyLink = (link) => {
        const spotifyLinkPattern = /^(https?:\/\/)?(www\.)?(spotify\.com|open\.spotify\.com)\/.+/;
        return spotifyLinkPattern.test(link);
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (!validateEventData(eventData, imageFile, validateQuestion)) return;

        const invalidLinks = eventData.eventLinks.filter(link => !isValidSpotifyLink(link));
        if (invalidLinks.length > 0) {
            alert("Please provide valid Spotify links. Invalid links: " + invalidLinks.join(', '));
            setLoading(false);
            return;
        }
        if (Number(eventData.ticketPrice) < 10) {
            alert("Ticket price must be at least 10");
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('eventName', eventData.eventName)
        formData.append('eventDescription', eventData.eventDescription)
        formData.append('eventImage', imageFile)
        formData.append('eventTime', eventData.eventTime)
        formData.append('eventMaxBookings', eventData.eventMaxBookings)
        formData.append('ticketPrice', eventData.ticketPrice)
        formData.append('eventLinks', eventData.eventLinks)
        formData.append('featured', featured)
        formData.append('location', JSON.stringify({
            type: "Point",
            coordinates: [coordinates?.longitude, coordinates?.latitude]  // [longitude, latitude]
        }));
        formData.append('placeId', coordinates?.placeId);
        formData.append('address', coordinates?.label);
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-create`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': authKey,

                }
            });
            const data = await response.json();
            if (response.status === 211) {
                alert(data?.message)
                removeLocalStorage()
                 navigate('/')
           }
            setResponse({ response: response, data: data })
            handleSpotifyStatusCode(response.status, data.message)
            if (response.ok) {
                setLoading(false)
                navigate(`/user-dashboard-event-details/${data.event._id}`);
            } else {
                console.error("Failed to create event");
                setLoading(false)
                // Handle failed event creation here
                alert(data.message)
            }
        } catch (err) {
            console.error("Error while creating event:", err);
            // Handle network or server error here
        }
    };


    return (
        <div className="basic-container">
            <UserStatusModel eventResponse={response} />
            <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>
            <EventCreateForm
                eventData={eventData}
                loading={loading}
                setFeatured={setFeatured}
                featured={featured}
                handleInputChange={handleInputChange}
                handleImageDrop={handleImageDrop} // Pass the function down
                handleSubmit={(e) => handleSubmit(e, imageFile)} // Pass imageFile to handleSubmit
                handleLinkChange={handleLinkChange}
                setCoordinates={setCoordinates}
            />
        </div>
    );
};

export default EventCreatePage;
