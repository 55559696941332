import React from 'react'
import { useNavigate } from 'react-router-dom';

const ChatExperiencePopup = ({setFirstTime}) => {
    const navigate = useNavigate();


      const handleSkip = () => {
        navigate('/user-buy-ticket')
    }
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-[150]">
                <div className="relative p-4 w-full max-w-lg max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                        <div className="p-4 md:p-5">
                            <div className='text-xl font-bold text-center'>
                            🎶 Chat Now for a Music-Powered Discount!
                            </div>
                            <div className='text-lg mt-5'>
                                Before booking your ticket, ask our AI chat assistant about your music preferences and unlock an exclusive discount. Chat now to personalize your experience and enjoy savings on your next event! 🎉
                            </div>
                            {/* <div className='flex justify-center mt-6 gap-4'> */}
                                {/* <button className="text-white rounded-lg text-lg !w-fit px-5 py-4 text-center animated-btn" onClick={() => setFirstTime(false)}><span className='text'>Okay</span></button> */}
                                <div className='flex gap-5'>
                    <button
                        // onClick={handleSpotifyLogin}
                        // onClick={handleChat}
                        onClick={() => setFirstTime(false)}
                        className="w-full py-3 animated-btn mt-4 bg-black font-bold text-white rounded-md"

                    >
                        <span className='text '>Continue</span>
                    </button>
                    <button
                        onClick={handleSkip}
                        className="w-full py-3 mt-4 bg-red-600 font-bold text-white rounded-md"

                    >
                        Skip
                    </button>
                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default ChatExperiencePopup
