import "../../../styles/sharedStyle.css";
import React from "react";
import EventCardCreator from "../../../components/events/eventCardCreator";
import "./user-dashboard-create.css";
import fakeEvent from "../../../fakeEvents/fakeEvents.json";
import { PORT_BACKEND_URL_SRC } from "../../../srcLocalPortReferenceBackend";

export const handleEditClick = (navigate, eventId) => {
  navigate(`/user-dashboard-event-edit/${eventId}`);
};

export const handleViewDetailsClick = (navigate, eventId) => {
  navigate(`/user-dashboard-event-details/${eventId}`);
};
export const handleShareClick = async (
  createdEvents,
  eventId,
  setSelectedEventForShare,
  setShowSharePopup
) => {
  try {
    const selectedEvent = createdEvents.find((event) => event._id === eventId);
    if (selectedEvent) {
      setSelectedEventForShare(selectedEvent);
      setShowSharePopup(true);
    }
    const response = await fetch(
      `${PORT_BACKEND_URL_SRC}/api/shared-event-info/${eventId}`
    );
    const result = await response.json();
    return result;
  } catch (e) {
    console.log("failed to share event", e);
  }
};

export const closeSharePopup = (
  setSelectedEventForShare,
  setShowSharePopup
) => {
  setSelectedEventForShare(null);
  setShowSharePopup(false);
};

export const handleLogout = async (navigate, PORT_BACKEND_URL_SRC) => {
  try {
    await fetch(`${PORT_BACKEND_URL_SRC}/logout`, {
      method: "POST",
      headers: { Authorization: localStorage.getItem("authKey") },
    });
    navigate("/");
    localStorage.removeItem("authKey");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("phoneVerificationToken");
    localStorage.removeItem("emailVerificationToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("flow");
  } catch (error) {
    console.error("Error logging out:", error);
    alert("Error logging out. Please try again.");
  }
};

export const handleNewsletter = (navigate) => {
  localStorage.removeItem("flow");
  navigate("/user-dashboard-event-newsletter");
};

export const handleAnalytics = (navigate) => {
  localStorage.removeItem("flow");
  navigate("/user-dashboard-event-analytics");
};

export const handleFreeChat = (navigate) => {
  localStorage.removeItem("flow");
  navigate("/user-free-chat");
};

export const handleSettings = (navigate) => {
  localStorage.removeItem("flow");
  navigate("/user-dashboard-create-settings");
};

export const handleCreateClick = (navigate) => {
  navigate("/user-dashboard-event-create");
};

export const renderEvents = (
  createdEvents,
  isLoading,
  handleEditClick,
  handleViewDetailsClick,
  handleShareClick
) => {
  if (isLoading) {
    return null;
  }

  const now = new Date();
  const upcomingEvents = createdEvents?.filter(
    (event) => new Date(event.eventTime) > now && event.status === "Active"
  );
  const pastEvents = createdEvents?.filter(
    (event) => new Date(event.eventTime) <= now && event.status === "Active"
  );
  const cancelledEvents = createdEvents?.filter(
    (event) => event.status === "InActive"
  );
  const upcomingFakeEvents = fakeEvent?.creatorFakeEvents?.filter(
    (event) => new Date(event.eventTime) > now
  );

  const pastFakeEvents = fakeEvent?.creatorFakeEvents?.filter(
    (event) => new Date(event.eventTime) <= now
  );

  return (
    <>
      <h2 className="text-2xl font-bold mb-8">Upcoming Events I'm Hosting</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {upcomingEvents?.length ? (
          upcomingEvents?.map((event) => (
            <EventCardCreator
              isFakeEvent={false}
              key={event._id}
              event={event}
              handleEditClick={handleEditClick}
              handleViewDetailsClick={handleViewDetailsClick}
              handleShareClick={() => handleShareClick(event._id)}
            />
          ))
        ) : createdEvents?.length === 0 ? (
          upcomingFakeEvents?.map((event) => (
            <EventCardCreator
              isFakeEvent={true}
              key={event._id}
              event={event}
              handleEditClick={handleEditClick}
              handleViewDetailsClick={handleViewDetailsClick}
              handleShareClick={() => handleShareClick(event._id)}
            />
          ))
        ) : (
          <PlaceholderCard />
        )}
      </div>

      <h2 className="text-2xl font-bold mb-8">Previous Events I've Hosted</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {pastEvents?.length ? (
          pastEvents?.map((event) => (
            <EventCardCreator
              isFakeEvent={false}
              key={event._id}
              event={event}
              handleEditClick={handleEditClick}
              handleViewDetailsClick={handleViewDetailsClick}
              handleShareClick={() => handleShareClick(event._id)}
            />
          ))
        ) : createdEvents?.length <= 0 ? (
          pastFakeEvents?.map((event) => (
            <EventCardCreator
              isFakeEvent={true}
              key={event._id}
              event={event}
              handleEditClick={handleEditClick}
              handleViewDetailsClick={handleViewDetailsClick}
              handleShareClick={() => handleShareClick(event._id)}
            />
          ))
        ) : (
          <PlaceholderCard />
        )}
      </div>

      <h2 className="text-2xl font-bold">Cancelled Events</h2>
      <p className="mb-8">We show you your canceled events because if someone booked the events, they will be reimbursed. When you cancel events, we will send a notification to the audience that booked.</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {cancelledEvents?.length ? (
          cancelledEvents?.map((event) => (
            <EventCardCreator
              isFakeEvent={false}
              key={event._id}
              event={event}
              handleEditClick={handleEditClick}
              handleViewDetailsClick={handleViewDetailsClick}
              handleShareClick={() => handleShareClick(event._id)}
            />
          ))
        ) : (
          <PlaceholderCard />
        )}
      </div>
    </>
  );
};

export const PlaceholderCard = () => {
  return (
    <div
      style={{
        width: "80vw", // 80% of the viewport width
        maxWidth: "400px", // Max width can still be 400px
        height: "25vh", // 25% of the viewport height
        maxHeight: "200px", // Max height can still be 200px
        marginBottom: "2rem",
      }}
      className="border dark:border-none border-gray-200 shadow rounded-md p-4 mb-4 bg-gray-200 dark:bg-gray-700"
    >
      {/* Your card content here */}
    </div>
  );
};
