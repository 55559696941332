import React, { useState } from "react";
import DetailEventTitle from "../../../components/events/detailSection/detailEventTitle";
import InputField from "../../../components/miscBlocks/inputField";
import ThreeDHeart from "../../../components/geometries/rotatingHeart";
import { useLocation, useNavigate } from "react-router-dom";
import { PORT_BACKEND_URL_SRC } from "../../../srcLocalPortReferenceBackend";
import { handleSpotifyStatusCode } from "../../../statuscodemanage/checkSpotifyStatus";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripe = require("stripe")(stripeKey);

const UserAddCard = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const message = searchParams.get("message");
  const userId = searchParams.get("userId") || localStorage.getItem("userId");
  const eventId = localStorage.getItem("eventId");
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    name: "",
    number: "",
    month: "",
    year: "",
    cvc: "",
    expiryDt: "",
  });
  const [cardMessagePopup, setCardMessagePopup] = useState({
    popup: false,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const onChangeExp = (e) => {
    var value = e.target.value;
    if (value.length > 5) {
      value = value.slice(0, -1);
    }
    setCardDetails({
      ...cardDetails,
      expiryDt: value,
    });
  };

  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const addCard = async () => {
    setLoading(true);
    const [selectedMonth, selectedYear] = cardDetails.expiryDt.split("/");
    const token = await stripe.tokens.create({
      card: {
        name: cardDetails.name,
        number: cardDetails.number,
        exp_month: selectedMonth,
        exp_year: "20" + selectedYear,
        cvc: cardDetails.cvc,
      },
    });
    try {
      const response = await fetch(
        `${PORT_BACKEND_URL_SRC}/stripe/create-card`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cardToken: token.id,
            userId: userId,
            name: cardDetails?.name,
          }),
        }
      );
      const data = await response.json();
      handleSpotifyStatusCode(response.status, data.message);
      if (response.ok) {
        setCardMessagePopup({
          ...cardMessagePopup,
          message: data.message,
          popup: true,
        });
        alert(data.message);
        localStorage.setItem("authKey", data.data.token);
        setLoading(false);
        if (eventId) {
          navigate(`/user-chat-experience/${localStorage.getItem("eventId")}`);
          // navigate(`/user-spotify-login`)
        } else {
          navigate("/user-discovery");
        }
      } else {
        setLoading(false);
        alert(data.message);
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
      return {
        success: false,
        message: "An error occurred while sending feedback.",
      };
    }
  };

  return (
    <div className="full-screen">
      <div className="flex flex-wrap">
        <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
          <div className="w-11/12 max-w-[700px] px-10 py-20 dark:bg-[#191a1b] rounded-3xl bg-white border-0 border-white">
            <div className="card">
              <DetailEventTitle eventName={"Card Details"} />
            </div>
            <div className="mt-8">
              <p className="mt-2 text-gray-600">{message}</p>
            </div>
            <div className="mt-8">
              <InputField
                label="Card Number"
                type="number"
                name="number"
                placeholder="Enter your Card Number"
                value={cardDetails.number}
                onChange={handleChange}
              />
              <InputField
                label="Card Holder Name"
                type="text"
                name="name"
                value={cardDetails.name}
                placeholder="Enter your Card Holder Name"
                onChange={handleChange}
              />
              <InputField
                label="CVC"
                type="password"
                name="cvc"
                value={cardDetails.cvc}
                placeholder="Enter your Cvc"
                onChange={handleChange}
              />
              <div className="card mt-5 mb-5">
                <label className="text-lg font-medium">Expiry</label>
                <input
                  type="text"
                  name="expiryData"
                  className="focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder="mm / yy"
                  onChange={onChangeExp}
                  value={expriy_format(cardDetails.expiryDt)}
                />
              </div>
              <div className="mt-12 flex flex-col ">
                <button
                  className="animated-btn"
                  style={{ zIndex: 9999 }}
                  onClick={addCard}
                >
                  <span className="text">
                    {loading ? "Creating..." : "Create"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-flex-container-2-3 overflow-x-hidden">
          <ThreeDHeart />
        </div>
      </div>
    </div>
  );
};

export default UserAddCard;
