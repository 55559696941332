import React, { useState } from "react";
import InputField from "../miscBlocks/inputField"; // Corrected the import path
import DetailEventDescription from "./detailSection/detailEventDescription";
import DetailEventTitle from "./detailSection/detailEventTitle";
import DragAndDropImage from "../miscBlocks/dragAndDropImage"; // Adjust the import path as necessary
import { PORT_BACKEND_URL_SRC } from "../../srcLocalPortReferenceBackend";
import LoadingData from "../loading/Loading";
import UnFeatureDeatilsPopup from "../popup/unFeatureDeatilsPopup";

const EventEditForm = ({
  eventData,
  loading,
  setImagePreview,
  imagePreview,
  setEventData,
  handleSubmit,
  handleLinkChange,
  handleImageDrop,
  handleDelete,
}) => {
  const spotifyLinkRegex =
    /^https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?.*)?$/;
  const [errors, setErrors] = useState(
    Array(eventData.eventLinks.length).fill(false)
  );
  const [isDragged, setIsDragged] = useState(false);
  const [isHoverSave, setIsHoverSave] = useState(false);
  const [isHoverDelete, setIsHoverDelete] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleLinkBlur = (index, value) => {
    if (!spotifyLinkRegex.test(value)) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = true; // Set error for this specific input
        return newErrors;
      });
    } else {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false; // Clear error if valid
        return newErrors;
      });
    }
  };

  // const saveButtonStyle = {
  //     backgroundColor: isHoverSave ? '#555555' : '#000000', // Change to darker on hover
  //     color: '#FFFFFF',
  // };

  const deleteButtonStyle = {
    backgroundColor: isHoverDelete ? "#555555" : "#FF0000", // Change to darker on hover
    color: "#FFFFFF",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prevState) => {
      const newState = { ...prevState, [name]: value };
      return newState;
    });
  };

  const now = new Date();

  // const message = "whdiwd"

  const formattedDate =
    now.getFullYear() +
    "-" +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + now.getDate()).slice(-2) +
    "T" +
    ("0" + now.getHours()).slice(-2) +
    ":" +
    ("0" + now.getMinutes()).slice(-2);

  return (
    <div className="event-edit-form-container">
      {showPopup && <UnFeatureDeatilsPopup setShowPopup={setShowPopup} />}
      <form onSubmit={handleSubmit} className="form-styling">
        {/* Essentials Section */}
        <div className="section">
          <div className="card">
            <div className="event-title-container flex justify-between items-center">
              <h1 className="event-name mb-0 whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
                Edit Event
              </h1>
              <h3 className="text-lg mr-4">${eventData.ticketPrice}</h3>
            </div>
          </div>
          {/* Display the image preview if available */}
          {imagePreview && !isDragged && (
            <div className="image-preview-container">
              <img
                src={PORT_BACKEND_URL_SRC + "/" + imagePreview}
                alt="Event"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            </div>
          )}

          <DragAndDropImage
            setIsDragged={setIsDragged}
            onImageDrop={handleImageDrop}
          />

          <InputField
            label="Event Name"
            id="eventName"
            name="eventName"
            type="text"
            required
            value={eventData.eventName}
            onChange={handleChange}
          />

          {/* <InputField
                        label="Description"
                        id="eventDescription"
                        name="eventDescription"
                        isTextArea={true}
                        required
                        value={eventData.eventDescription}
                        onChange={handleChange}
                    /> */}

          <div className="card mt-5 mb-5">
            <div className="items-center bg-white dark:bg-black dark:text-white rounded-2xl overflow-hidden shadow">
              <label
                htmlFor="eventDescription"
                className="text-lg font-medium"
              >
                Description
              </label>
              <textarea
                placeholder="Description..."
                id="eventDescription"
                name="eventDescription"
                value={eventData.eventDescription}
                onChange={handleChange}
                rows="3"
                className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full"
              />
            </div>
          </div>

          <div className="card mt-5 mb-5">
            <label className="text-lg font-medium">Event Time</label>
            <input
              name="eventTime"
              value={formatDate(eventData.eventTime)}
              onChange={handleChange}
              className="focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
              min={formattedDate}
              type="datetime-local"
            />
          </div>

          <div className="card mt-5 mb-5">
            {/* <div className='flex flex-col mt-4'> */}
            <label className="text-lg font-medium">Maximum Capacity</label>
            <input
              name="eventMaxBookings"
              value={eventData.eventMaxBookings}
              onChange={handleChange}
              className="focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
              type="number"
              required={true}
              min={1}
            />
          </div>
        </div>

        {/* Artists Links Section */}
        <div className="section">
          <div className="card mt-5">
            <DetailEventTitle eventName="Previews" />
          </div>
          <div className="card mt-4">
            <DetailEventDescription description="The preview will render Spotify tracks, Soundcloud tracks, TikTok posts and Instagram posts links only, not profile links." />
          </div>
          {eventData.eventLinks.map((link, index) => (
            <div className="card mt-5 mb-5">
              <label className="text-lg font-medium">{`Creator Link ${
                index + 1
              }`}</label>
              <input
                key={`artistLink-${index}`}
                id={`artistLink-${index}`}
                name={`artistLink-${index}`}
                value={link}
                onChange={(e) => handleLinkChange(index, e.target.value)}
                onBlur={(e) => handleLinkBlur(index, e.target.value)}
                className="focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent"
                type="text"
                required={true}
              />
              {errors[index] && (
                <p className="text-red-500 text-sm mt-1">
                  Please enter a valid Spotify link
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-between">
          <div>
            <input
              type="checkbox"
              id="featured"
              className="text-lg w-5 h-5 relative top-0.5"
              disabled
              name="featured"
              checked={eventData.isFeatured}
            />
            <label for="featured" className="ml-2 text-lg font-semibold">
              Featured Event?
              {eventData?.isFeatured && (
                <span className="text-gray-500 ml-2 font-semibold text-sm">{`(${eventData?.isFeatured})`}</span>
              )}
            </label>
          </div>
          <div>
            {eventData.isFeatured && (
              <span
                className="ml-4 font-semibold text-[#4535C1] text-md cursor-pointer underline"
                onClick={() => setShowPopup(true)}
              >
                How to unfeature?
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-4 space-x-4">
          <button
            type="submit"
            className="!w-fit animated-btn mb-8"
            onMouseEnter={() => setIsHoverSave(true)}
            onMouseLeave={() => setIsHoverSave(false)}
            onClick={handleSubmit}
          >
            <span className="text !text-[18px]">
              {loading ? <LoadingData /> : "Save"}
            </span>
          </button>
          <button
            type="button"
            className="deleteform-button"
            style={deleteButtonStyle}
            disabled={loading}
            onMouseEnter={() => setIsHoverDelete(true)}
            onMouseLeave={() => setIsHoverDelete(false)}
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
};
export default EventEditForm;
