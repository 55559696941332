import React, { useRef, useState } from "react";

const SpotifyLinkPreviewer = ({ link, setIndexClick, indexClick, songValue, setContainerClick }) => {
  const isSpotifyLink = link.includes("open.spotify.com");
  const embedUrl = link.replace("open.spotify.com", "open.spotify.com/embed");

  const timeoutRef = useRef(null);
  const [clicked, setClicked] = useState(false);

  const pauseAllSpotifySongs = () => {
    const iframes = document.querySelectorAll("iframe[src*='open.spotify.com']");
    iframes.forEach(iframe => {
      iframe.contentWindow.postMessage({ command: "pause" }, "*");
    });
  };

  const handleClick = () => {
    pauseAllSpotifySongs();
    const existing = indexClick.find((item) => item.id === songValue);

    if (!existing) {
      setIndexClick((prev) => [...prev, { id: songValue, played: false }]);
      setContainerClick((prev) => [...prev, { id: songValue, songClicked: false, checkboxClicked: false }]);

      timeoutRef.current = setTimeout(() => {
        setIndexClick((prev) =>
          prev.map((item) =>
            item.id === songValue ? { ...item, played: true } : item
          )
        );
        setContainerClick((prev) =>
          prev.map((item) =>
            item.id === songValue ? { ...item, songClicked: true } : item
          )
        );
      }, 5000);
    } else if (!existing.played) {
      timeoutRef.current = setTimeout(() => {
        setIndexClick((prev) =>
          prev.map((item) =>
            item.id === songValue ? { ...item, played: true } : item
          )
        );
        setContainerClick((prev) =>
          prev.map((item) =>
            item.id === songValue ? { ...item, songClicked: true } : item
          )
        );
      }, 5000);
    }
    setClicked(true);
  };

  return isSpotifyLink ? (
    <div className="relative sm:max-w-sm ">
      <iframe
        src={embedUrl}
        className="w-full h-20 sm:h-24 rounded-lg border-none"
        allow="encrypted-media"
        title="Spotify music preview"
      />
      {!clicked && (
        <div
          onClick={handleClick}
          className="absolute top-0 left-0 w-full h-[70%] bg-transparent cursor-pointer"
          style={{
            zIndex: 5,
            pointerEvents: clicked ? "none" : "auto",
          }}
        />
      )}
    </div>
  ) : (
    <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline break-words">
      {link}
    </a>
  );
};

export default SpotifyLinkPreviewer;