import { handleSpotifyStatusCode } from '../statuscodemanage/checkSpotifyStatus';

const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const fetchParticipantData = async (eventId, currentPage, searchTerm) => {
    try {
        const participantResponse = await fetch(`${PORT_BACKEND_URL_SRC}/get-participant-list?eventId=${eventId}&page=${currentPage}&limit=${12}&keyword=${searchTerm}`, {
            method: 'GET',
            headers: { Authorization: localStorage.getItem('authKey') }
        });
        const participantsData = await participantResponse.json();
        handleSpotifyStatusCode(participantResponse.status, participantsData.message)
        if (participantResponse.status === 200) {
            if (participantsData.success) {
                return participantsData
            }
        }
    } catch (error) {
        console.error('Error fetching created events:', error);
    }
}