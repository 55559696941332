/**
 * Fetches event details from the backend.
 * @param {string} eventId - ID of the event to fetch.
 * @param {Function} setEventData - Function to set the event data.
 * @param {string} PORT_BACKEND_URL_SRC - Backend URL source.
 */

import { useState, useEffect } from 'react';
export function useFetchEventDetailsWithoutAuth(eventId, PORT_BACKEND_URL_SRC, requestingStatus, setLoading) {
  const [eventData, setEventData] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/public/events/${eventId}?requestingStatus=${requestingStatus}`, {
          method: 'GET',
        });
        const data = await response.json();
        if(setLoading){
          setLoading(false)
        }
        if (response.ok) {
          setEventData(data.event);
          setBookingDetails(data?.bookingParticipantInfo)
        } else {
          console.error(data.message || 'Failed to fetch event details.');
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchData();
  }, [eventId, PORT_BACKEND_URL_SRC]);

  return [eventData, setEventData, bookingDetails];
}