const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const spotifyConnect = async ({ navigate, usedFor }) => {
    try {
        // Construct base URL
        let url = `${"http://192.168.1.27:3012"}/spotify-authorize-url?userId=${localStorage.getItem('userId')}`;
        
        // Conditionally append the 'usedFor' parameter if it exists
        if (usedFor) {
            url += `&usedFor=${encodeURIComponent(usedFor)}`;
        }

        const response = await fetch(url, {
            method: 'GET',
        });

        const data = await response.json();
        if (data.success && data.spotifyAuthorizeURL) {
            // Redirect the user to Spotify's authorization page
            window.location.href = data.spotifyAuthorizeURL;
        } else if (data.success && data.redirectUrl) {
            // If the login is successful and a redirect URL is provided, navigate there
            navigate(data.redirectUrl);
        } else {
            throw new Error('Failed to retrieve Spotify authorization URL');
        }
    } catch (error) {
        console.error('Error during Spotify login:', error);
        alert('Failed to initiate Spotify authentication. Please try again.');
    }
};
