import React from "react";
import { PORT_BACKEND_URL_SRC } from "../../srcLocalPortReferenceBackend";
import SpotifyLinkPreviewer from "../previews/spotifyLinkPreviewer";
import YoutubeChatPreview from "../previews/youtubechatPreview";

const DocumentPreviewPopup = ({ documentContent, documentTitle, onClose }) => {
  // Check if documentContent starts with "Question" to identify the correct format
  // const isQuestionFormat = documentContent.startsWith("Question");

  // Function to parse document content into questions and answers
  // const parseDocumentContent = (content) => {
  //     const splitContent = content.split('\n').filter(line => line.trim() !== '');
  //     return splitContent.map((line, index) => {
  //         // Split questions and answers into separate elements
  //         return (
  //             <React.Fragment key={index}>
  //                 {line.startsWith('Question') ? <strong>{line}</strong> : <p>{line}</p>}
  //             </React.Fragment>
  //         );
  //     });
  // }

  // // Function to render document content as a list for the non-question format
  // const renderListContent = (content) => {
  //     // Assuming the content needs to be split into lines for the non-question format
  //     const lines = content.split('\n').filter(line => line.trim() !== '');
  //     return lines.map((line, index) => <li key={index}>{line}</li>);
  // };

  const extractLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex);
  };

  const renderSocialMediaPreview = (link) => {
    if (typeof link === "string") {
      // Trim any trailing parentheses
      const cleanedLink = link.replace(/\)$/, "");

      if (cleanedLink.includes("open.spotify.com")) {
        return <SpotifyLinkPreviewer link={cleanedLink} />;
      } else if (cleanedLink.includes("youtube.com")) {
        return <YoutubeChatPreview link={cleanedLink} />;
      } else {
        return <div className="unsupported-link">Unsupported link type.</div>;
      }
    } else {
      console.error("The link is not a string:", link);
      return <div className="unsupported-link">Invalid link format.</div>;
    }
  };

  const renderMessageContent = (content) => {
    console.log("content in renderMessageContent:", content);
    const links = extractLinks(content);

    if (links) {
      const textWithoutLinks = content.replace(/https?:\/\/[^\s]+/g, "").trim();

      return (
        <>
          {textWithoutLinks && <p>{textWithoutLinks}</p>}
          {links.map((link, index) => (
            <div key={index} className="mt-2">
              {renderSocialMediaPreview(link)}
            </div>
          ))}
        </>
      );
    }
    return content;
  };
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content dark:bg-[#191a1b]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="card">
          <h2 className="font-bold ">{documentTitle}</h2>
        </div>
        <div className="card chat-history-container chat-data">
          {/* {documentContent?.data?.messages?.map((message, index) => 
                        (
                        <div key={index} className={`mt-4 ${
                            message.messageSource === "ParticipantMessage" || message.messageSource === "CreatorMessage"
                              ? "flex justify-end gap-2"
                              : "flex justify-start flex-row-reverse gap-2"
                          }`}>
                            <div
                                className={`chat-message ${message.messageSource === "ParticipantMessage" || message.messageSource === "CreatorMessage" ? "user-message" : "bot-message"}`}
                            >
                                {message.content} 
                            </div>
                            <img className='w-8 h-8 rounded-full' src={message.messageSource === "ParticipantMessage" || message.messageSource === "CreatorMessage" ? PORT_BACKEND_URL_SRC + '/' + documentContent?.data?.participantInfo?.profilePic : "/images/ai_bot_image.jpeg"} alt='Profile Pic'/>
                        </div>
                    ))} */}

          {documentContent?.data?.messages?.map((message, index) => (
            <div
              key={index}
              className={`mt-4 ${
                message.messageSource === "ParticipantMessage" ||
                message.messageSource === "CreatorMessage"
                  ? "flex justify-end gap-2"
                  : "flex justify-start flex-row-reverse gap-2"
              }`}
            >
              <div
                className={`chat-message ${
                  message.messageSource === "ParticipantMessage" ||
                  message.messageSource === "CreatorMessage"
                    ? "user-message"
                    : "bot-message"
                }`}
              >
                {renderMessageContent(message.content)}
              </div>
              <img
                className="w-8 h-8 rounded-full"
                src={
                  message.messageSource === "ParticipantMessage" ||
                  message.messageSource === "CreatorMessage"
                    ? PORT_BACKEND_URL_SRC +
                      "/" +
                      documentContent?.data?.participantInfo?.profilePic
                    : "/title-image.png"
                }
                alt="Profile Pic"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="py-3 px-5 !bg-[#686D76] text-white rounded font-bold "
          >
            Close
          </button>
        </div>
      </div>
      <style jsx>{`
        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          max-height: 80%;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
          max-width: 800px;
          width: 90%;
          // overflow-y: auto;
        }
        .document-body {
          background: #f4f4f4;
          margin-top: 20px;
          padding: 10px;
          border-radius: 5px;
        }
        .chat-data {
          max-height: 368px; /* Adjust the calculation based on the button height */
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: #888 #f1f1f1;
          margin-bottom: 20px;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default DocumentPreviewPopup;
