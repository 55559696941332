import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend'
import RotatingGeoCylinder from "../../../components/geometries/others/rotatingGeoCylinder";
import RotatingGeoCylinderInverse from "../../../components/geometries/others/rotatingGeoCylinderInverse";

const UserSignupAs = () => {
  const [isCheckedCreator, setIsCheckedCreator] = useState(false);
  const [isCheckedAudience, setIsCheckedAudience] = useState(false);
  const [popup, setPopup] = useState({ show: false, type: "" });
  const navigate = useNavigate();
  const handleRole = async (userRole) => {
    navigate("/signup-select-character", {
      state: { isRegularUser: userRole },
    });
  };
  const handleShowPopup = (type) => {
    // setPopup({ show: true, type });
    if(type === "creator"){
        setIsCheckedAudience(false)
    } else if(type === "audience"){
        setIsCheckedCreator(false)
  };
  setPopup({show: true, type});
}
  
  const handleConfirmPopup = () => {
    if (popup.type === "creator") setIsCheckedCreator(true);
    if (popup.type === "audience") setIsCheckedAudience(true);
    setPopup({ show: false, type: "" });
  };
  return (
    <div className="">
      <div className="">
        <div className="dark:bg-[#191a1b] ">
          <div className="p-4 flex flex-col gap-2 rounded-3xl dark:bg-[#191a1b] border-0 border-white">
            <div className="event-title-container">
              <h1 className="event-name text-center">Get Started</h1>
            </div>
            <div className="flex flex-col mt-4 lg:mt-12 lg:flex-row items-center justify-center gap-10 lg:gap-20">
              <div className="card !py-6 flex flex-col items-center w-[320px] sm:w-[440px]">
                <RotatingGeoCylinder />
                <div className="font-bold text-2xl text-center mt-12">
                  Creator
                </div>
                <div className="text-lg text-center mt-6">
                  Cheers to a new beginning!
                </div>
                <label className="flex items-center gap-2 mt-4">
                  <input
                    type="checkbox"
                    checked={isCheckedCreator}
                    onChange={() =>
                      !isCheckedCreator && handleShowPopup("creator")
                    }
                    className="w-4 h-4"
                    disabled={isCheckedCreator} // Disable after selection
                  />
                  <span>I understood</span>
                </label>

                {/* <button className='px-8 !w-fit mt-6 py-3 animated-btn' onClick={() => handleRole(false)}><span className='text'>Sign up</span></button> */}
                <button
                  className={`px-8 !w-fit mt-6 py-3 animated-btn ${
                    !isCheckedCreator ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleRole(false)}
                  disabled={!isCheckedCreator}
                >
                  <span className="text">Sign up</span>
                </button>
              </div>
              <div className="card flex !py-6 flex-col items-center w-[320px] sm:w-[440px]">
                <RotatingGeoCylinderInverse />
                <div className="font-bold text-2xl text-center mt-12">
                  Audience
                </div>
                <div className=" text-lg text-center mt-6">
                  Ready to have some fun?
                </div>
                <label className="flex items-center gap-2 mt-4">
                  <input
                    type="checkbox"
                    checked={isCheckedAudience}
                    onChange={() =>
                      !isCheckedAudience && handleShowPopup("audience")
                    }
                    className="w-4 h-4"
                    disabled={isCheckedAudience} // Disable after selection
                  />
                  <span>I understood</span>
                </label>
                {/* <button className='px-8 !w-fit mt-6 py-3 animated-btn' onClick={() => handleRole(true)}><span className='text'>Sign up</span></button> */}
                <button
                  className={`px-8 !w-fit mt-6 py-3 animated-btn ${
                    !isCheckedAudience ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleRole(true)}
                  disabled={!isCheckedAudience}
                >
                  <span className="text">Sign up</span>
                </button>
              </div>

              {popup.show && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
                  <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center max-w-sm w-full">
                    <p className="text-lg font-bold mb-4 break-words">
                      {popup.type === "creator"
                        ? "A Creator account allows you to create events and connect with Stripe for payments. Please confirm before proceeding."
                        : "An Audience account can only purchase tickets and discover events. Pick wisely!"}
                    </p>
                    <button
                      className="px-6 py-2 bg-blue-600 text-white rounded-md w-full"
                      onClick={handleConfirmPopup}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignupAs;
