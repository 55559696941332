import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';

const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

export const useFetchEventDetails = () => {
    const [eventData, setEventData] = useState({
        eventName: '',
        eventDescription: '',
        eventImage: '',
        eventTime: '',
        eventLocation: '',
        eventMaxBookings: '',
        eventLinks: new Array(6).fill(''),
    });
    const { eventId } = useParams();

    useEffect(() => {
        async function fetchEventDetails() {
            try {
                const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event/${eventId}`, {
                    method: 'GET',
                    //credentials: 'include',
                    headers: {
                        'Authorization': localStorage.getItem('authKey')
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    data.event.eventLinks = data.event.eventLinks || new Array(6).fill('');
                    setEventData(data.event);
                } else {
                    throw new Error(data.message || 'Failed to fetch event details.');
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        }
        fetchEventDetails();
    }, [eventId]);

    return [eventData, setEventData];
};


export const validateQuestion = (question) => {
    const wordCount = question.trim().split(/\s+/).length;
    const isValidLength = wordCount > 10;
    const containsVulgarities = /some_regex_for_vulgarities/.test(question);
    return isValidLength && !containsVulgarities;
}


export const validateEventData = (eventData, imageFile, validateQuestion) => {
    const {
        eventName,
        eventDescription,
        eventTime,
        eventLocation,
        eventMaxBookings,
        formQuestions
    } = eventData;

    let missingFields = [];

    if (!eventName) missingFields.push("Event Name");
    if (!eventDescription) missingFields.push("Event Description");
    if (!eventTime) missingFields.push("Event Time");
    if (!eventLocation) missingFields.push("Event Location");
    if (eventMaxBookings == null) missingFields.push("Event Maximum Bookings");

    if (!imageFile) {
        missingFields.push("Event Image");
    } else {
        if (imageFile.size > 1048576) { // 1MB in bytes
            missingFields.push("Event Image too large (max 5MB)");
        }
    }

    if (formQuestions && formQuestions.some(question => !validateQuestion(question))) {
        missingFields.push("Invalid or Inappropriate Questions");
    }
    return true;
};


export const handleSubmit = async (eventData, eventId, navigate, imageFile, validateEventData, validateQuestion, setResponse) => {
    if (!validateEventData(eventData, imageFile, validateQuestion)) {
        return; // Stop the submission if validation fails
    }
    const formData = new FormData();
    formData.append('eventData', JSON.stringify(eventData));
    if (imageFile) {
        formData.append('eventImage', imageFile);
    }

    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-update/${eventId}`, {
            method: 'PUT',
            //credentials: 'include',
            headers: { Authorization: localStorage.getItem('authKey') },
            body: formData, // Pass FormData object
            // Don't set Content-Type header, let the browser set it
        });

        const data = await response.json();
        setResponse({ response: response, data: data })
        handleSpotifyStatusCode(response.status, data.message)
        if (response.ok) {
            navigate("/user-dashboard-create"); // Redirect on success
        } else {
            console.error('Failed to update event:', data.message);
        }
    } catch (err) {
        console.error('Error:', err);
    }
};

export const handleDelete = async (eventId, navigate) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-cancel-by-creator/${eventId}`, {
            method: 'DELETE',
            // credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
              },
        });
        const data = await response.json();
        if (response.ok) {
            navigate("/user-dashboard-create"); // Redirect after deletion
        } else {
            console.error('Failed to delete event:', data.message);
        }
    } catch (err) {
        console.error('Error:', err);
    }
};

