// import "../../../styles/sharedStyle.css";
// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import DetailEventTitle from "../../../components/events/detailSection/detailEventTitle";
// import DetailEventImage from "../../../components/events/detailSection/detailEventImage";
// import DetailEventDescription from "../../../components/events/detailSection/detailEventDescription";
// import DetailEventDescriptionIndex from "../../../components/events/detailSection/detailEventDescriptionIndex";
// import DetailEventTime from "../../../components/events/detailSection/detailEventTime";
// import DetailEventLocation from "../../../components/events/detailSection/detailEventLocation";
// import DetailSocialMediaLinks from "../../../components/events/detailSection/detailSocialMediaLinks";
// import { useFetchEventDetailsWithoutAuth } from "./user-dashboard-event-share-utility";
// import { removeLocalStorage } from "../../../utils/removeLocalstorage";
// import PageLoader from "../../../utils/PageLoader";
// import MetaTags from "../../../components/metaTags";
// import { Helmet } from "react-helmet";

// const {
//   PORT_BACKEND_URL_SRC,
// } = require("../../../srcLocalPortReferenceBackend");

// function UserDashboardEventShare() {
//   const { eventId } = useParams();
//   const [loading, setLoading] = useState(true);
//   const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(
//     eventId,
//     PORT_BACKEND_URL_SRC,
//     "SHARED_EVENT_LINK",
//     setLoading
//   );
//   const navigate = useNavigate();
//   const authKey = localStorage.getItem("authKey");
//   localStorage.setItem("eventId", eventId);

//   const [meta, setMeta] = useState({
//     title: "Event Share",
//     description: "Join the event!",
//     image: "",
//   });

//   useEffect(() => {
//     if (eventData) {
//       setMeta({
//         title: eventData.eventName || "Event Share",
//         description: eventData.eventDescription || "Join the event!",
//         image: eventData.eventImage?.startsWith("http")
//           ? eventData.eventImage
//           : `${window.location.origin}/${eventData.eventImage}`,
//       });
//     }
//   }, [eventData]);

//   const checkUserAuthentication = async (eventId) => {
//     if (authKey) {
//       try {
//         const response = await fetch(
//           `${PORT_BACKEND_URL_SRC}/check-regular-user-auth/${eventId}`,
//           {
//             headers: {
//               Authorization: authKey,
//             },
//           }
//         );
//         const apiData = await response.json();

//         if (response.status === 211) {
//           alert(apiData.message);
//           removeLocalStorage();
//           navigate("/");
//         } else if (response.status === 244) {
//           if (apiData.message) {
//             if (localStorage.getItem("eventId")) {
//               navigate(
//                 `/user-chat-experience/${localStorage.getItem("eventId")}`
//               );
//             }
//           } else {
//             navigate("/user-buy-ticket");
//           }
//         } else if (response.status === 200) {
//           localStorage.setItem("authKey", apiData.data.token);
//           navigate(`/user-chat-experience/${eventId}`);
//         } else {
//           alert(apiData.message);
//           navigate("/");
//         }
//       } catch (error) {
//         console.error("Error checking authentication status:", error);
//       }
//     } else {
//       navigate("/");
//     }
//   };

// //   <Helmet>
// //   <meta property="og:url" content="hi I am" />
// //   <meta property="og:type" content="website" />
// //   <meta property="og:title" content="hi I am" />
// //   <meta property="og:description" content="hi I am" />
// // </Helmet>

//   return (
//     <>

//       <Helmet>
//         <meta
//           property="og:url"
//           content={`${window.location.origin}/event/${eventId}`}
//         />
//         <meta property="og:type" content="website" />
//         <meta property="og:title" content={meta.title} />
//         <meta property="og:description" content={meta.description} />
//         <meta property="og:image" content={meta.image} />
//       </Helmet>

//       {loading ? (
//         <PageLoader />
//       ) : (
//         <>
//           {eventData && (
//             <div className="basic-container">
//               <div className="card">
//                 <DetailEventTitle eventName={eventData.eventName} />
//               </div>
//               <div className="flex-container">
//                 <div className="card flex-item">
//                   <DetailEventImage
//                     eventImage={eventData.eventImage}
//                     altText={eventData.eventName}
//                   />
//                 </div>
//                 <div className="card flex-item">
//                   <div className="card dark:bg-[#191a1b]">
//                     <DetailEventDescriptionIndex
//                       name="Max Bookings"
//                       value={eventData.eventMaxBookings}
//                     />
//                   </div>
//                   <div className="card dark:bg-[#191a1b]">
//                     <DetailEventDescriptionIndex
//                       name="Price"
//                       value={"$" + eventData.ticketPrice}
//                     />
//                   </div>
//                   {eventData.isFeatured === "Approved" && (
//                     <div className="card">
//                       <DetailEventDescriptionIndex
//                         name="Featured"
//                         value={eventData.isFeatured}
//                       />
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div className="card">
//                 <DetailEventDescription
//                   description={eventData.eventDescription}
//                 />
//               </div>
//               <div className="card">
//                 <DetailEventTime eventTime={eventData.eventTime} />
//               </div>
//               <div className="card">
//                 <DetailEventLocation location={eventData.address} />
//               </div>
//               <h1 className="participants-title">Previews</h1>
//               <p className="participants-description">
//                 Get a taste of the event.
//               </p>
//               <DetailSocialMediaLinks links={eventData.eventLinks} />
//               <div className="flex flex-col gap-3 justify-between md:flex-row mt-10 mb-4">
//                 <button
//                   type="button"
//                   className="py-6 px-24 !w-fit animated-btn rounded font-bold"
//                   onClick={() => checkUserAuthentication(eventId)}
//                 >
//                   <span className="text">Book</span>
//                 </button>
//                 <button
//                   type="button"
//                   className="py-6 px-20 !w-fit animated-btn rounded font-bold"
//                   onClick={() => navigate("/guest-email")}
//                 >
//                   <span className="text">Book as a guest</span>
//                 </button>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </>
//   );
// }

// export default UserDashboardEventShare;

import "../../../styles/sharedStyle.css";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DetailEventTitle from "../../../components/events/detailSection/detailEventTitle";
import DetailEventImage from "../../../components/events/detailSection/detailEventImage";
import DetailEventDescription from "../../../components/events/detailSection/detailEventDescription";
import DetailEventDescriptionIndex from "../../../components/events/detailSection/detailEventDescriptionIndex";
import DetailEventTime from "../../../components/events/detailSection/detailEventTime";
import DetailEventLocation from "../../../components/events/detailSection/detailEventLocation";
import DetailSocialMediaLinks from "../../../components/events/detailSection/detailSocialMediaLinks";
import { useFetchEventDetailsWithoutAuth } from "./user-dashboard-event-share-utility";
import { removeLocalStorage } from "../../../utils/removeLocalstorage";
import PageLoader from "../../../utils/PageLoader";
import UserDetailsPopup from "../../../components/popup/userDetailsPopup";

const {
  PORT_BACKEND_URL_SRC,
} = require("../../../srcLocalPortReferenceBackend");

function UserDashboardEventShare() {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  // const textareaRef = useRef(null);
  const [eventData, setEventData, bookingDetails] =
    useFetchEventDetailsWithoutAuth(
      eventId,
      PORT_BACKEND_URL_SRC,
      "SHARED_EVENT_LINK",
      setLoading
    );
  const navigate = useNavigate();
  const authKey = localStorage.getItem("authKey");
  localStorage.setItem("eventId", eventId);

  const [meta, setMeta] = useState({
    title: "Event Share",
    description: "Join the event!",
    image: "",
  });

  // ✅ Remove old meta tags on mount
  useEffect(() => {
    const metaTagsToRemove = [
      "description",
      "title",
      "og:description",
      "og:title",
      "twitter:title",
      "twitter:description",
      "og:image",
      "twitter:image",
    ];

    metaTagsToRemove.forEach((name) => {
      const metaTag = document.querySelector(
        `meta[name="${name}"], meta[property="${name}"]`
      );
      if (metaTag) {
        metaTag.remove();
      }
    });

    // Remove title tag
    const titleTag = document.querySelector("title");
    if (titleTag) {
      titleTag.remove();
    }
  }, []);

  // const adjustHeight = () => {
  //   const textarea = textareaRef.current;
  //   if (textarea) {
  //     textarea.style.height = "auto"; // Reset height first
  //     textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
  //   }
  // };

  // useEffect(() => {
  //   adjustHeight(); // Adjust height when component mounts
  // }, [eventData.eventDescription]);

  // ✅ Update meta dynamically when event data loads
  useEffect(() => {
    if (eventData) {
      setMeta({
        title: eventData.eventName || "Event Share",
        description: eventData.eventDescription || "Join the event!",
        image: eventData.eventImage?.startsWith("http")
          ? eventData.eventImage
          : `${window.location.origin}/${eventData.eventImage}`,
      });

      // ✅ Add the "eventData" meta tag dynamically
      const metaTag = document.createElement("meta");
      metaTag.name = "eventData";
      metaTag.content = JSON.stringify(eventData);
      document.head.appendChild(metaTag);
      // adjustHeight();
      return () => {
        metaTag.remove(); // Cleanup on unmount
      };
    }
  }, [eventData]);

  // ✅ Check if "eventData" meta tag exists after 3 seconds
  useEffect(() => {
    setTimeout(() => {
      const eventMetaTag = document.querySelector('meta[name="eventData"]');
      if (eventMetaTag) {
        const eventData = JSON.parse(
          eventMetaTag.getAttribute("content") || "{}"
        );
        console.log("Parsed Event Data:", eventData);
      } else {
        console.log("Event meta tag not found");
      }
    }, 3000);
  }, []);

  const checkUserAuthentication = async (eventId) => {
    if (authKey) {
      try {
        const response = await fetch(
          `${PORT_BACKEND_URL_SRC}/check-regular-user-auth/${eventId}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const apiData = await response.json();

        if (response.status === 211) {
          alert(apiData.message);
          removeLocalStorage();
          navigate("/");
        } else if (response.status === 244) {
          if (apiData.message) {
            if (localStorage.getItem("eventId")) {
              navigate(
                `/user-chat-experience/${localStorage.getItem("eventId")}`
              );
            }
          } else {
            navigate("/user-buy-ticket");
          }
        } else if (response.status === 200) {
          localStorage.setItem("authKey", apiData.data.token);
          navigate(`/user-chat-experience/${eventId}`);
        } else {
          alert(apiData.message);
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    } else {
      navigate("/");
    }
  };

  // ✅ Copy to clipboard function
  const copyToClipboard = () => {
    try {
      // const shareUrl = `${window.location.origin}/user-dashboard-event-share/${eventId}?redirectTo=/user-chat-experience`;
      const textArea = document.createElement("textarea");
      // textArea.value = shareUrl;
      textArea.value = `https://api.2n1t3.com/api/shared-event-info/${eventId}`;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("Link copied to clipboard");
    } catch (error) {
      console.error("Clipboard write error:", error);
      alert("Failed to copy the link. Please try again.");
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {popup && (
            <UserDetailsPopup eventId={eventData._id} setPopup={setPopup} />
          )}
          {eventData && (
            <div className="basic-container">
              <div className="card">
                <div className="flex justify-between">
                  <DetailEventTitle eventName={eventData.eventName} />

                  {/* <button
                  onClick={copyToClipboard}
                  className="py-2 px-4 bg-[#686D76] text-white rounded font-bold"
                >
                  Copy Link@@@
                </button> */}
                  <div
                    // style={{display:"flex", backgroundColor:"white", width:"40px", height:"40px",
                    // justifyContent:"center" }}
                    style={{
                      display: "flex",
                      // backgroundColor: "white",
                      width: "30px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px", // Optional: Adds rounded corners
                      overflow: "hidden", // Ensures the image doesn't overflow
                      alignSelf: "center",
                    }}
                  >
                    <img
                      src="/maskGroup.png"
                      alt="Copy"
                      onClick={copyToClipboard}
                      className="hidden dark:block"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                    />
                    <img
                      src="/share.png"
                      alt="Copy"
                      onClick={copyToClipboard}
                      className="block dark:hidden"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <p style={{ fontWeight: "bold" }}>{eventData.artistName}</p>
              </div>
              <div className="flex-container">
                <div className="card flex-item">
                  <DetailEventImage
                    eventImage={eventData.eventImage}
                    altText={eventData.eventName}
                  />
                </div>
                <div className="card flex-item">
                  <div className="card dark:bg-[#191a1b]">
                    <DetailEventDescriptionIndex
                      name="Max Bookings"
                      value={eventData.eventMaxBookings}
                    />
                  </div>
                  <div className="card dark:bg-[#191a1b]">
                    <DetailEventDescriptionIndex
                      name="Price"
                      value={"$" + eventData.ticketPrice}
                    />
                  </div>
                  <div className="card dark:bg-[#191a1b] flex justify-between">
                    <span className="description-name font-bold dark:text-white">
                      Attendees
                    </span>
                    {bookingDetails && (
                      <div className="flex">
                        {bookingDetails?.data?.map((item, index) => {
                          return (
                            <div
                              className={`p-1 bg-white rounded-full cursor-pointer ${
                                index !== 0 && "ml-[-15px]"
                              }`}
                              title={item?.participantFirstName}
                              onClick={() => setPopup(true)}
                            >
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  PORT_BACKEND_URL_SRC + "/" + item?.profilePic
                                }
                                alt="image"
                              />
                            </div>
                          );
                        })}
                        {bookingDetails?.totalCount > 3 && (
                          <div
                            className="p-1 ml-[-15px] cursor-pointer bg-white text-white rounded-full"
                            onClick={() => setPopup(true)}
                          >
                            <div className="w-8 h-8 rounded-full text-xs bg-gray-300 flex justify-center items-center">
                              +{bookingDetails?.totalCount - 3}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {eventData.isFeatured === "Approved" && (
                    <div className="card">
                      <DetailEventDescriptionIndex
                        name="Featured"
                        value={eventData.isFeatured}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="card">
                {/* <DetailEventDescription
                  description={eventData.eventDescription}
                /> */}
                {/* <div className="card mt-5 mb-5">
          <div className="items-center bg-white dark:bg-black dark:text-white rounded-2xl overflow-hidden shadow">
            
            <textarea
              ref={textareaRef}
              readOnly
              defaultValue={eventData.eventDescription}
              onInput={adjustHeight} // Adjust height dynamically
              style={{ resize: "none", overflow: "hidden" }}
              className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full"
            />
          </div>
        </div> */}

                {/* <div className="card mt-5 mb-5 px-2 sm:px-4"> */}
                <div className="items-center bg-white dark:bg-black dark:text-white rounded-2xl overflow-hidden shadow p-4">
                  {/* <textarea
          ref={textareaRef}
          readOnly
          defaultValue={eventData.eventDescription}
          onInput={adjustHeight} // Adjust height dynamically
          style={{ resize: "none", overflow: "hidden" }}
          className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full"

        /> */}
                  <div className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full">
                    {eventData.eventDescription}
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="card">
                <DetailEventTime eventTime={eventData.eventTime} />
              </div>
              <div className="card">
                <DetailEventLocation location={eventData.address} />
              </div>
              <h1 className="participants-title">Previews</h1>
              <p className="participants-description">
                Get a taste of the event.
              </p>
              <DetailSocialMediaLinks links={eventData.eventLinks} />
              <div className="flex flex-col gap-3 justify-between md:flex-row mt-10 mb-4">
                <button
                  type="button"
                  className="py-6 px-24 !w-fit animated-btn rounded font-bold"
                  onClick={() => checkUserAuthentication(eventId)}
                >
                  <span className="text">Book</span>
                </button>
                <button
                  type="button"
                  className="py-6 px-20 !w-fit animated-btn rounded font-bold"
                  onClick={() => navigate("/guest-email")}
                >
                  <span className="text">Book as a guest</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default UserDashboardEventShare;

// import '../../../styles/sharedStyle.css';
// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
// import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
// import DetailEventDescriptionIndex from '../../../components/events/detailSection/detailEventDescriptionIndex';
// import DetailEventTime from '../../../components/events/detailSection/detailEventTime';
// import DetailEventLocation from '../../../components/events/detailSection/detailEventLocation';
// import DetailSocialMediaLinks from '../../../components/events/detailSection/detailSocialMediaLinks';
// import { useFetchEventDetailsWithoutAuth } from './user-dashboard-event-share-utility';
// import { removeLocalStorage } from '../../../utils/removeLocalstorage';
// import PageLoader from '../../../utils/PageLoader';
// import MetaTags from '../../../components/metaTags';

// const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

// function UserDashboardEventShare() {
//   const { eventId } = useParams();
//   const [loading, setLoading] = useState(true);
//   const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(eventId, PORT_BACKEND_URL_SRC, "SHARED_EVENT_LINK", setLoading);
//   const navigate = useNavigate();
//   const authKey = localStorage.getItem('authKey');
//   localStorage.setItem('eventId', eventId);

//   const [meta, setMeta] = useState({
//     title: "Event Share",
//     description: "Join the event!",
//   });

//   useEffect(() => {
//     if (eventData) {
//       setMeta({
//         title: eventData.eventName || "Event Share",
//         description: eventData.eventDescription || "Join the event!",
//       });
//     }
//   }, [eventData]);

//   const checkUserAuthentication = async (eventId) => {
//     if (authKey) {
//       try {
//         const response = await fetch(`${PORT_BACKEND_URL_SRC}/check-regular-user-auth/${eventId}`, {
//           headers: {
//             'Authorization': authKey
//           }
//         });
//         const apiData = await response.json();

//         if (response.status === 211) {
//           alert(apiData.message);
//           removeLocalStorage();
//           navigate('/');
//         } else if (response.status === 244) {
//           if (apiData.message) {
//             if (localStorage.getItem('eventId')) {
//               navigate(`/user-chat-experience/${localStorage.getItem('eventId')}`);
//             }
//           } else {
//             navigate('/user-buy-ticket');
//           }
//         } else if (response.status === 200) {
//           localStorage.setItem('authKey', apiData.data.token);
//           navigate(`/user-chat-experience/${eventId}`);
//         } else {
//           alert(apiData.message);
//           navigate('/');
//         }
//       } catch (error) {
//         console.error('Error checking authentication status:', error);
//       }
//     } else {
//       navigate('/');
//     }
//   };

//   return (
//     <>
//       <MetaTags {...meta} />
//       {loading ? (
//         <PageLoader />
//       ) : (
//         <>
//           {eventData && (
//             <div className="basic-container">
//               <div className="card">
//                 <DetailEventTitle eventName={eventData.eventName} />
//               </div>
//               <div className="flex-container">
//                 <div className="card flex-item">
//                   <div className="card dark:bg-[#191a1b]">
//                     <DetailEventDescriptionIndex name="Max Bookings" value={eventData.eventMaxBookings} />
//                   </div>
//                   <div className="card dark:bg-[#191a1b]">
//                     <DetailEventDescriptionIndex name="Price" value={'$' + eventData.ticketPrice} />
//                   </div>
//                   {eventData.isFeatured === "Approved" && (
//                     <div className="card">
//                       <DetailEventDescriptionIndex name="Featured" value={eventData.isFeatured} />
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div className="card">
//                 <DetailEventDescription description={eventData.eventDescription} />
//               </div>
//               <div className="card">
//                 <DetailEventTime eventTime={eventData.eventTime} />
//               </div>
//               <div className="card">
//                 <DetailEventLocation location={eventData.address} />
//               </div>
//               <h1 className="participants-title">Previews</h1>
//               <p className="participants-description">Get a taste of the event.</p>
//               <DetailSocialMediaLinks links={eventData.eventLinks} />
//               <div className="flex flex-col gap-3 justify-between md:flex-row mt-10 mb-4">
//                 <button
//                   type="button"
//                   className='py-6 px-24 !w-fit animated-btn rounded font-bold'
//                   onClick={() => checkUserAuthentication(eventId)}
//                 >
//                   <span className='text'>Book</span>
//                 </button>
//                 <button
//                   type="button"
//                   className='py-6 px-20 !w-fit animated-btn rounded font-bold'
//                   onClick={() => navigate('/guest-email')}
//                 >
//                   <span className='text'>Book as a guest</span>
//                 </button>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </>
//   );
// }

// export default UserDashboardEventShare;
