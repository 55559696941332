import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchUsersData } from '../../api/fetchUsersData';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';

const LoadingSpinner = () => (
    <div className="flex justify-center items-center w-full h-20 dark:bg-black">
        <span className="dark:text-white text-lg font-semibold">Loading...</span>
    </div>
);

const UserDetailsPopup = ({ eventId, setPopup }) => {
    const [usersData, setUsersData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        const getallParticipantData = async () => {
            setLoading(true);
            const data = await fetchUsersData(eventId, currentPage, searchTerm);
            setLoading(false);

            if (data?.data) {
                setUsersData(data.data.data);
                setTotalRows(data.data.totalCount);
            }
        };

        getallParticipantData();
    }, [searchTerm, currentPage, eventId]);

    const columns = [
        {
            name: <div className='text-lg font-bold'>Profile</div>,
            selector: row => row.transactionId,
            sortable: true,
            width: "290px",
            format: (row) => (
                <div className='py-3 flex items-center gap-3'>
                    <img src={PORT_BACKEND_URL_SRC + '/' + row?.profilePic} className='w-16 h-16 rounded-full' alt="User" />
                    <div className='text-lg'>{row?.participantFirstName} {row?.participantlastName}</div>
                </div>
            ),
        },
        {
            name: <div className='text-lg font-bold'>Social Media</div>,
            selector: row => row.currency,
            sortable: true,
            format: (row) => (
                <div className='flex gap-5'>
                    <img className='w-10 h-10 cursor-pointer' src='/images/instagramIcon.png'
                        onClick={() => window.open(row.participantInstagramProfile, '_blank')} alt="Instagram" />
                    {/* <img className='w-10 h-10 cursor-pointer rounded-lg' src='/images/twitterLogo.jpeg' onClick={() => window.open(row.participantTwitterProfile, '_blank')} alt="Twitter" /> */}
                </div>
            ),
        },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen z-50">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            User Details
                        </h3>
                        <button type="button" onClick={() => setPopup(false)} className="end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className='p-4 pt-1 mt-3'>
                        <input
                            name='searchTerm'
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                                setCurrentPage(1)
                            }}
                            className='focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent'
                            placeholder="Search profile..."
                            type="text"
                        />
                        <div className='mt-5 rounded'>
                        <DataTable
                            columns={columns}
                            data={usersData}
                            pagination
                            paginationServer
                            paginationPerPage={5}
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            highlightOnHover
                            progressPending={loading}
                            progressComponent={<LoadingSpinner />} // Custom loading component
                            paginationComponentOptions={{
                                rowsPerPageText: '',
                                rangeSeparatorText: 'of',
                                noRowsPerPage: true,
                            }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetailsPopup;
