import React from 'react';
import '../../../styles/detailIntro.css';
const DetailEventTitle = ({ eventName }) => {
  return (
    <div className="event-title-container">
      <h1 className="event-name w-full overflow-hidden break-words">{eventName}</h1>
    </div>
  );
};

export default DetailEventTitle;
