import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import Chat from "../../../components/miscBlocks/chat";
import LoadingData from "../../../components/loading/Loading";
import Avatar from "../../../components/interactiveAvatar/avatar";
import { PORT_BACKEND_URL_SRC } from "../../../srcLocalPortReferenceBackend";
import { handleSpotifyStatusCode } from "../../../statuscodemanage/checkSpotifyStatus";
import { useNavigate } from "react-router-dom";
import SpotifyLinkPreviewer from "../../../components/previews/spotifyLinkPreviewer";
import YoutubeChatPreview from "../../../components/previews/youtubechatPreview";
import {
  handleAnalytics,
  handleFreeChat,
  handleLogout,
  handleNewsletter,
  handleSettings,
} from "../dashboardCreate/user-dashboard-create-utility";
import { getCurrentUser } from "../../../api/authAPI";
import ChatHamburgerMenu from "../../../components/miscBlocks/chatHamburgerMenu";
import { getAvatar } from "../../../api/getAvatar";
import PageLoader from "../../../utils/PageLoader";

const UserFreeChat = () => {
  const youtubeRegex =
    /https:\/\/(youtu\.be\/\w+|www\.youtube\.com\/watch\?v=[\w-]+)/g;
  const spotifyRegex =
  /(https:\/\/open\.spotify\.com\/[^\s\)]+)/g;
  const [chatTranscriptId, setChatTranscriptId] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [previousData, setPreviousData] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [currentAvatar, setCurrentAvatar] = useState("");
  const chatHistoryRef = useRef(null);
  const [responseLoading, setResponseLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [isDoneShowing, setIsDoneShowing] = useState(true);

  const [expressionIndex, setExpressionIndex] = useState(0);
  const [expressionArr, setExpressionArr] = useState("");
  const [actArray, setActArray] = useState({
    eventName: "dance 1 baked",
    eventExpression: "dance 1 baked",
  });
  const bottomRef = useRef();
  const lastMessageRef = useRef();
  // useEffect(() => {
  //   if (!isDoneShowing) {
  //     const scrollInterval = setInterval(() => {
  //       if (chatHistoryRef.current) {
  //         chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  //       }
  //     }, 100); // Adjust timing as needed

  //     return () => clearInterval(scrollInterval);
  //   }
  // }, [isDoneShowing]);

  useEffect(() => {
    console.log(isDoneShowing, "isDoneShowing");
    if (!isDoneShowing && !responseLoading) {
      const scrollInterval = setInterval(() => {
        if (bottomRef.current) {
          bottomRef?.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      }, 100);

      return () => clearInterval(scrollInterval);
    }
  }, [isDoneShowing, chatHistory]);

  useEffect(() => {
    if (lastMessageRef?.current) {
      lastMessageRef?.current?.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [chatHistory && chatHistory[chatHistory?.length - 1]]);

  const getPrviousChat = async () => {
    setLoading(true);
    try {
      const chatResponse = await fetch(
        `${PORT_BACKEND_URL_SRC}/fetch-creator-transcript`,
        {
          method: "GET",
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      const chatData = await chatResponse.json();
      
      setLoading(false);
      handleSpotifyStatusCode(chatResponse.status, chatData.message);
      if (chatResponse.status === 200) {
        if (chatData.success) {
          setPreviousData(chatData?.data?.messages);
          setChatTranscriptId(chatData?.chatTranscriptId);
        }
      }
    } catch (error) {
      console.error("Error fetching created events:", error);
    }
  };

  const isFemaleAvatar = (avatar) => {
    return avatar === "FEMALE_OUTFIT" || avatar === "FEMALE_UNIFORM";
  };

  useEffect(() => {
    const fetchData = async () => {
      await getPrviousChat();
      const avatarData = await getAvatar();
      const defaultAvatar = avatarData?.userInfo?.defaultAvatarModel;
      setCurrentAvatar(avatarData?.userInfo?.defaultAvatarModel);
      if (isFemaleAvatar(defaultAvatar)) {
        setActArray((prev) => ({ ...prev, eventExpression: "dance 1 baked" }))
        console.log("Yes, it's female");
      } else {
        setActArray((prev) => ({ ...prev, eventExpression: "dance" }))
        console.log("Yes, it's male");
      }
      // if(avatarData?.userInfo?.defaultAvatarModel)
    };
    fetchData();
  }, []);

  const obj = {
    FEMALE_OUTFIT: ["bored", "happy double peace", "dance 2 baked", "sad depressio", "dance 1 baked",],
    FEMALE_UNIFORM: ["bored", "happy double peace", "dance 2 baked", "sad depressio", "dance 1 baked",],
    MALE_OUTFIT: ["happy", "sad", "bored", "dance"],
    MALE_UNIFORM: ["happy", "sad", "bored", "dance"],
  }

  useEffect(() => {
    setExpressionArr(obj[currentAvatar])
  }, [currentAvatar]);

  useEffect(() => {
    if (previousData !== null) {
      const initialBotMessage = {
        type: "initialbot",
        message:
          'Welcome to 2n1t3. If you are not familiar with this please service take a look at this tutorial, just to get you started, this allows you to know your audience... ask something like "what is my audience listening to? " this will only work after you create your first events, the more events the better this chat will be',
        element: (
          <div>
            Welcome to 2n1t3. If you are not familiar with this please
            service take a{" "}
            <a
              className="text-blue-600"
              href={process.env.REACT_APP_REDIRECT_URL}
            >
              look at this tutorial
            </a>
            , just to get you started, this allows you to know your audience...
            ask something like "what is my audience listening to? " this will
            only work after you create your first events, the more events the
            better this chat will be
          </div>
        ),
      };

      if (previousData.length > 0) {
        setChatHistory(previousData);
      } else {
        setChatHistory([initialBotMessage]);
      }
    }
  }, [previousData]);

  const startRandomExpressions = () => {
    console.log("*******************Gettinginto the startRandomExpressions ");
    // const expressionArr = ['dance', 'happy', 'sad', 'bored', 'excited', 'surprised', 'angry'];
    if (currentAvatar.match()) {

    }
    // const expressionArr = ["dance", "happy", "sad", "bored", "surprised"];
    const blendShapes = [
      "eyeWideLeft",
      "mouthUpperUpLeft",
      "jawOpen",
      "mouthClose",
    ];

    // Function to get the next expression in the loop
    const getNextExpression = () => {
      const expression = expressionArr[expressionIndex];
      // Increment the index and reset to 0 if it exceeds the last index
      console.log("expressionIndex Before :", expressionIndex);
      console.log("expression", expression);
      let upDatedValue = (expressionIndex + 1) % expressionArr.length;
      setExpressionIndex(upDatedValue);
      console.log("expressionIndex After : ", expressionIndex);
      return expression;
    };
    const randomShape = () =>
      blendShapes[Math.floor(Math.random() * blendShapes.length)];

    // Function to trigger the random action
    const selectedExpression = getNextExpression();
    const selectedShape = randomShape();
    setActArray({
      eventExpression: selectedExpression,
      eventName: selectedShape,
    });
  };

  const handleSendMessage = async (userMessage) => {
    try {
      startRandomExpressions();
      setChatHistory((chatHistory) => [
        ...chatHistory,
        { type: "user", message: userMessage },
      ]);
      setResponseLoading(true);
      setIsDoneShowing(false);
      const response = await fetch(
        `${PORT_BACKEND_URL_SRC}/search-query-for-creator`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authKey"),
          },
          body: JSON.stringify({ message: userMessage, chatTranscriptId }),
        }
      );
      const jsonData = await response.json;

      handleSpotifyStatusCode(response.status, jsonData.message);
      if (!response.ok) {
        alert("Failed to send message");
        setResponseLoading(false);
      }

      const data = await response.json();
      const aiResponseText = data.data.AIResponse;
      setChatHistory((chatHistory) => [
        ...chatHistory,
        { type: "bot", message: aiResponseText },
      ]);

      if (aiResponseText) {
        setResponseLoading(false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const renderSocialMediaPreview = (link) => {
    if (link.includes("open.spotify.com")) {
      return <SpotifyLinkPreviewer link={link} />;
    } else if (link.includes("youtube.com")) {
      return <YoutubeChatPreview link={link} />;
    } else {
      return <div className="unsupported-link">Unsupported link type.</div>;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      let users = await getCurrentUser(
        navigate,
        localStorage.getItem("authKey"),
        setResponse,
        "dashboard"
      );
      setCurrentUser(users.data);
      setProfilePic(users.data.userProfilePic)
    };
    getUser();
  }, []);

  const removeLinks = (text) => {
    const urlRegex = /\(?(https?:\/\/[^\s)]+)\)?/g;
    return text?.replace(urlRegex, "").trim().replace(/\s+/g, " ");
  };
 console.log("currentUser@@@@", currentUser);
 
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="user-chat-experience-container">
          <div className="z-50 mt-4 py-10 flex justify-end md:justify-center">
            <ChatHamburgerMenu
              currentUser={currentUser}
              handleLogout={() => handleLogout(navigate, PORT_BACKEND_URL_SRC)}
              handleNewsletter={() => handleNewsletter(navigate)}
              handleAnalytics={() => handleAnalytics(navigate)}
              handleFreeChat={() => handleFreeChat(navigate)}
              handleSettings={() => handleSettings(navigate)}
              setMenuVisible={setMenuVisible}
              menuVisible={menuVisible}
              isCreator={true}
            />
          </div>

          <div className="avatar-container">
            {currentAvatar !== "" && <Avatar currentAvatar={currentAvatar} actArray={actArray} />}
          </div>

          <div className="chat-history-container" ref={chatHistoryRef}>
            {chatHistory.map((message, index) => (
              // const message1 = removeLinks(message?.text);
              // <div
              //   key={index}
              //   className={`mt-4 ${
              //     message.type === "UserMessage"
              //       ? "flex justify-end gap-2 "
              //       : "flex justify-start flex-col gap-2"
              //   }`}
              // >
              <div
              key={index}
              className={`mt-4 flex ${
                message.type === "user"
                  ? "justify-end gap-2 "
                  : "justify-end flex-row-reverse gap-2"
              }`}
            >
                {/* <div
                  key={index}
                  className={`message ${
                    message.type === "user" ? "user-message" : "bot-message"
                  }`}
                > */}
                <div
                      className={`message ${
                        message.type === "user" ? "user-message" : "bot-message"
                      }`}
                    >
                  {message.type === "user" ? (
                    removeLinks(message.message)
                  ) : message.responseType === "FETCH_API_RESPONSE" ? (
                    removeLinks(message.message)
                  ) : message.type == "initialbot" ? (
                    message?.element
                  )
                   : (
                    <TypeAnimation
                      cursor={false}
                      sequence={[removeLinks(message.message), () => setIsDoneShowing(true)]}
                      speed={90}
                    />
                    // <h1>Hello WOlrd</h1>
                  )
                  }

                  {/* <div className="flex flex-col gap-3 mt-3"> */}
                    {message.message &&
                      []
                        .concat(
                          message.message.match(youtubeRegex) || [],
                          message.message.match(spotifyRegex) || []
                        )
                        .map((newLink, linkIndex) => {
                          console.log("new link, ::::", newLink);
                          return (
                            <div key={linkIndex} className="mt-2">
                              {renderSocialMediaPreview(newLink)}
                            </div>
                          );
                        })}
                  {/* </div> */}
                  
                </div>
                <img
                      className="w-10 h-10 rounded-full"
                      src={
                        message.type === "user"
                          ? `${PORT_BACKEND_URL_SRC}/${profilePic}`
                          // ? null
                          : "/title-image.png"
                      }
                      alt="Profile Pic"
                    />
              </div>
            ))}

            {responseLoading && (
              <div className="flex justify-start flex-col gap-2">
                <div className="message bot-message">
                  <LoadingData />
                </div>
              </div>
            )}
             <div ref={bottomRef} />
             <div ref={lastMessageRef} />
          </div>

          <div className="chat-input-container dark:bg-black">
            <Chat
              isDoneShowing={isDoneShowing}
              responseLoading={responseLoading}
              onSendMessage={handleSendMessage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserFreeChat;
