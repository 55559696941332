import React from "react";
import { useNavigate } from "react-router-dom";

const StripeCheckPopup = ({
  stripeAcknowledgementMeesage,
  setIsOpen,
  message,
  userId,
  email,
}) => {
  const navigate = useNavigate();
  return (
    <div
      id="authentication-modal"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabindex="-1"
      aria-hidden="true"
      className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen"
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Issue in Stripe Account
            </h3>

            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5">
            <p className="text-lg">{message}</p>
            <div className="flex justify-center mt-6 gap-4">
              <button
                className="text-white bg-black py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center"
                onClick={() =>
                  navigate(
                    `/stripe-connect?message=${stripeAcknowledgementMeesage}&userId=${userId}&email=${email}`
                  )
                }
              >
                Connect
              </button>
              <button
                className="text-white bg-red-600 py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center"
                onClick={() => setIsOpen(false)}
              >
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeCheckPopup;
