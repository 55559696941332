import React, { useEffect, useRef } from "react";
import { formatDate } from "../../utils/dateFormatter";
// import BufferToBase64 from '../../utils/bufferToBase64';
import "../../styles/eventCardCreator.css";
import { PORT_BACKEND_URL_SRC } from "../../srcLocalPortReferenceBackend";
import { useNavigate } from "react-router-dom";

function EventCardCreator({
  isFakeEvent,
  event,
  handleEditClick,
  handleViewDetailsClick,
  handleShareClick,
  isPlaceholder,
}) {
  const navigate = useNavigate();
  // const textareaRef = useRef(null);

  // const adjustHeight = () => {
  //   const textarea = textareaRef.current;
  //   if (textarea) {
  //     textarea.style.height = "auto"; // Reset height to auto
  //     textarea.style.height = textarea.scrollHeight + "px"; // Set height to fit content
  //   }
  // };

  // useEffect(() => {
  //   adjustHeight(); // Adjust height when component mounts
  // }, [event.eventDescription]);

  if (isPlaceholder) {
    return (
      <div className="card">
        <h3 className="text-xl font-bold">Event Name</h3>
        <p className="text-gray-600">Event Date</p>
        <div className="w-full h-40 bg-gray-200 rounded mt-2"></div>{" "}
        {/* Placeholder for image */}
        <div className="mt-4">
          <p className="text-gray-600">Event Description</p>
        </div>
      </div>
    );
  }

  // Format the event date
  const eventDateFormatted = formatDate(event.eventTime);

  // const imageSrc = BufferToBase64(event.eventImage);
  // Determine if the event is in the past
  const isPastEvent = new Date(event.eventTime) < new Date();

  // Function to auto-resize the textarea

  return (
    <div className="card">
      <h3 className="text-xl font-bold whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
        {event.eventName}
      </h3>
      <p className="text-gray-600 event-date dark:text-gray-400">
        {eventDateFormatted}
      </p>
      <img
        className="w-full h-40 object-cover rounded mt-2"
        src={
          !isFakeEvent
            ? PORT_BACKEND_URL_SRC + "/" + event.eventImage
            : event.eventImage
        }
        alt={event.eventName}
      />
      <div className="mt-4">
        {/* <p
          className="text-gray-600 event-description !block dark:text-gray-400 !whitespace-nowrap !w-full !overflow-hidden !overflow-ellipsis"
          title={event.eventDescription}
        >
          {event.eventDescription}
        </p> */}

        {/* <div className="card mt-5 mb-5"> */}
        <div className="mb-4 items-center bg-white dark:bg-black dark:text-white rounded-2xl overflow-hidden shadow">
          {/* <textarea
              ref={textareaRef}
              readOnly
              defaultValue={event.eventDescription}
              onInput={adjustHeight} // Adjust height dynamically
              style={{ resize: "none", overflow: "hidden" }}
              className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full"
            /> */}
          <div className="focus:outline-none dark:bg-black dark:text-white focus:border-black text-black border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 w-full">
            {event.eventDescription}
          </div>
        </div>
        {/* </div> */}

        <div className="button-group">
          {!isPastEvent && event.status === "Active" && (
            <button
              onClick={() => {
                !isFakeEvent
                  ? handleEditClick(event._id)
                  : navigate(`/user-dashboard-event-edit/${event._id}`, {
                      state: { eventId: event._id },
                    });
              }}
              className="button"
            >
              Edit
            </button>
          )}
          {!isPastEvent && event.status === "Active" && (
            <button
              onClick={() => {
                !isFakeEvent
                  ? handleViewDetailsClick(event._id)
                  : navigate(`/user-dashboard-event-details/${event._id}`, {
                      state: { event: event },
                    });
              }}
              className="button"
            >
              check in
            </button>
          )}
          {/* Conditionally render the Check In/Details button based on whether the event is in the past */}
          {isPastEvent && event.status === "Active" && (
            <button
              onClick={() => {
                !isFakeEvent
                  ? handleViewDetailsClick(event._id)
                  : navigate(`/user-dashboard-event-details/${event._id}`, {
                      state: { event: event },
                    });
              }}
              className="button"
            >
              Details
              {/* {isPastEvent ? "Details" : "Check in"} */}
            </button>
          )}

          {/* Hide the Share button for past events */}
          {!isPastEvent && event.status === "Active" && (
            <button
              onClick={() => handleShareClick(event._id)}
              className="button"
            >
              Share
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventCardCreator;
