import "../../../styles/sharedStyle.css";
import "../../../styles/chatHistory.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chat from "../../../components/miscBlocks/chat";
import Avatar from "../../../components/interactiveAvatar/avatar";
import { handleSpotifyStatusCode } from "../../../statuscodemanage/checkSpotifyStatus";
import LoadingData from "../../../components/loading/Loading";
import { TypeAnimation } from "react-type-animation";
import TicketPriceProgress from "../../../components/ticketDiscount/ticketPriceProgress";
import YoutubeChatPreview from "../../../components/previews/youtubechatPreview";
import SpotifyLinkPreviewer from "../../../components/previews/spotifyLinkPreviewer";
import { useFetchEventDetailsWithoutAuth } from "../dashboardCreate/user-dashboard-event-share-utility";
import ChatExperiencePopup from "../../../components/popup/chatExperiencePopup";
import { getAvatar } from "../../../api/getAvatar";
import { removeLocalStorage } from "../../../utils/removeLocalstorage";
import LikeButton from "../../../components/previews/shineButton";
import "./user-chat-button.css";

const {
  PORT_BACKEND_URL_SRC,
} = require("../../../srcLocalPortReferenceBackend");

function UserChatExperience() {
  const navigate = useNavigate();
  const eventId = localStorage.getItem("eventId");
  const chatTranscriptId = localStorage.getItem("chatTranscriptId");

  const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(
    eventId,
    PORT_BACKEND_URL_SRC,
    "SHARED_EVENT_LINK"
  );

  const [chatHistory, setChatHistory] = useState([]);
  const [prviewLinks, setPreviewLinks] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState("");
  const chatHistoryRef = useRef(null);
  const [responseLoading, setResponseLoading] = useState(false);
  const [previewPrices, setPreviewPrices] = useState({});
  const [firstTime, setFirstTime] = useState(false);
  const [isDoneShowing, setIsDoneShowing] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [regeneratedMessage, setRegeneratedMessage] = useState("");
  const [indexClick, setIndexClick] = useState([]); // will now hold objects like { id, played }
  const [containerClick, setContainerClick] = useState([]);
  const [songArrForSoundEffect, setSongArrForSoundEffect] = useState([]);
  const [checkDisable, setCheckDisable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [selectedSongs, setSelectedSongs] = useState([]);
  const bottomRef = useRef();
  const lastMessageRef = useRef();
  let globalSongIndex = 0;

  const [actArray, setActArray] = useState({
    eventName: "dance",
    eventExpression: "dance",
  });
  const selectionSound = useRef();
  console.log(responseLoading, "responseLoading");

  useEffect(() => {
    console.log(isDoneShowing, "isDoneShowing");
    if (!isDoneShowing && !responseLoading) {
      const scrollInterval = setInterval(() => {
        if (bottomRef.current) {
          bottomRef?.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      }, 100);

      return () => clearInterval(scrollInterval);
    }
  }, [isDoneShowing, chatHistory]);

  useEffect(() => {
    if (lastMessageRef?.current) {
      lastMessageRef?.current?.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [chatHistory && chatHistory[chatHistory?.length - 1]]);
  const playSelectionSound = () => {
    // selectionSound.current.play();
    if (selectionSound.current) {
      selectionSound.current.play();
    } else {
      selectionSound.current.pause();
    }
  };

  const youtubeRegex =
    /https:\/\/(youtu\.be\/\w+|www\.youtube\.com\/watch\?v=[\w-]+)/g;

  const spotifyRegex =
    /https:\/\/open\.spotify\.com\/(track|artist)\/[a-zA-Z0-9]+(?:\?[a-zA-Z0-9=&]+)?/g;

  useEffect(() => {
    // Simulate a bot initiating the conversation after the component mounts
    const initialBotMessage = {
      type: "bot",
      text: "Let's talk about music! What are your favorite artists?",
    };
    setChatHistory([initialBotMessage]);
  }, []);

  useEffect(() => {
    setFirstTime(true);
    localStorage.removeItem("chatTranscriptId");
  }, []);

  const passChatTranscript = async () => {
    try {
      const transcriptResponse = await fetch(
        `${PORT_BACKEND_URL_SRC}/handle-chat-vectore-messages/${localStorage.getItem(
          "chatTranscriptId"
        )}`,
        {
          method: "GET",
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      const transcriptData = await transcriptResponse.json();
      handleSpotifyStatusCode(
        transcriptResponse.status,
        transcriptData.message
      );
      if (transcriptResponse.status === 211) {
        alert(transcriptData.message);
        removeLocalStorage();
        navigate("/");
      }
      if (transcriptResponse.status === 200) {
        if (transcriptResponse.success) {
          return transcriptData;
        }
      } else {
        alert(transcriptData?.message);
      }
    } catch (error) {
      console.error("Error fetching created events:", error);
    }
  };

  const obj = {
    FEMALE_OUTFIT: [
      "bored",
      "happy double peace",
      "dance 2 baked",
      "sad depressio",
      "dance 1 baked",
    ],
    FEMALE_UNIFORM: [
      "bored",
      "happy double peace",
      "dance 2 baked",
      "sad depressio",
      "dance 1 baked",
    ],
    MALE_OUTFIT: ["happy", "sad", "bored", "dance"],
    MALE_UNIFORM: ["happy", "sad", "bored", "dance"],
  };

  useEffect(() => {
    setExpressionArr(obj[currentAvatar]);
  }, [currentAvatar]);

  const isFemaleAvatar = (avatar) => {
    return avatar === "FEMALE_OUTFIT" || avatar === "FEMALE_UNIFORM";
  };

  useEffect(() => {
    const fetchData = async () => {
      const avatarData = await getAvatar();
      const defaultAvatar = avatarData?.userInfo?.defaultAvatarModel;
      setCurrentAvatar(avatarData?.userInfo?.defaultAvatarModel);
      if (isFemaleAvatar(defaultAvatar)) {
        setActArray((prev) => ({ ...prev, eventExpression: "dance 1 baked" }));
        console.log("Yes, it's female");
      } else {
        setActArray((prev) => ({ ...prev, eventExpression: "dance" }));
        console.log("Yes, it's male");
      }
    };
    fetchData();
  }, []);

  const handleSkip = async () => {
    navigate("/user-buy-ticket", { state: previewPrices });
    if (chatTranscriptId) {
      await passChatTranscript();
    }

    localStorage.removeItem("chatTranscriptId");
  };

  function removePreviewText(inputString) {
    // Define the text to be removed using a regular expression
    const regex = /https:\/\/open\.spotify\.com\/track\/[A-Za-z0-9]+/g;

    // Replace the matched text with an empty string
    const result = inputString?.replace(regex, "");

    return result;
  };

  const pauseAllSpotifySongs = () => {
    const iframes = document.querySelectorAll("iframe[src*='open.spotify.com']");
    iframes.forEach(iframe => {
      iframe.contentWindow.postMessage({ command: "pause" }, "*");
    });
  };

  const handleReSendMessage = async () => {
    setShowButton(false);
    try {
      pauseAllSpotifySongs();
      setResponseLoading(true);
      setIsDoneShowing(false);
      const response = await fetch(
        `${PORT_BACKEND_URL_SRC}/user-chat-experience/${eventId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authKey"),
          },
          body: JSON.stringify({
            message: regeneratedMessage,
            chatTranscriptId: localStorage.getItem("chatTranscriptId"),
          }),
        }
      );
      // const jsonData = await response.json
      const data = await response.json();

      if (response.status === 210) {
        alert(data.message)
        navigate('/user-dashboard-book')
      }

      handleSpotifyStatusCode(response.status, data.message);
      if (response.status === 229) {
        setRegeneratedMessage(data.data.previousMessage);
        setResponseLoading(false);
        setShowButton(true);
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to send message");
      }
      const aiResponseText = data.data.AIResponse;
      localStorage.setItem("chatTranscriptId", data.data.chatTranscriptId);
      setChatHistory((chatHistory) => [
        ...chatHistory,
        { type: "bot", text: aiResponseText },
      ]);

      if (aiResponseText) {
        setResponseLoading(false);

        // get the all preview links
        const youtubeLinks = aiResponseText.match(youtubeRegex) || [];
        const spotifyLinks = aiResponseText.match(spotifyRegex) || [];
        const allLinks = [...youtubeLinks, ...spotifyLinks];
        setPreviewLinks(prviewLinks + allLinks.length);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // This assumes you have a state named `chatHistory` and a method `setChatHistory` to update it.
  const handleSendMessage = async (userMessage) => {
    pauseAllSpotifySongs();
    if (chatHistory.length > 24) {
      alert(
        "You’ve reached your chat limit and will now be redirected to the payment page. Once your payment is complete, you’ll unlock a great and memorable event experience!"
      );
      navigate("/user-buy-ticket", { state: previewPrices });
      if (chatTranscriptId) {
        await passChatTranscript();
      }
      localStorage.removeItem("chatTranscriptId");
    } else {
      try {
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { type: "user", text: userMessage },
        ]);

        setResponseLoading(true);
        setIsDoneShowing(false);

        const response = await fetch(
          `${PORT_BACKEND_URL_SRC}/user-chat-experience/${eventId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("authKey"),
            },
            body: JSON.stringify({
              message: userMessage,
              chatTranscriptId: localStorage.getItem("chatTranscriptId"),
            }),
          }
        );

        // const jsonData = await response.json
        const data = await response.json();

        if (response.status === 210) {
          alert(data.message)
          navigate('/user-dashboard-book')
        }

        handleSpotifyStatusCode(response.status, data.message);
        localStorage.setItem("chatTranscriptId", data.data.chatTranscriptId);

        if (response.status === 229) {
          setRegeneratedMessage(data.data.previousMessage);
          setResponseLoading(false);
          setShowButton(true);
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to send message");
        }

        const aiResponseText = data.data.AIResponse;
        console.log("📩 Raw AI Response Text:", aiResponseText);
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { type: "bot", text: aiResponseText },
        ]);

        if (aiResponseText) {
          setResponseLoading(false);

          // get the all preview links
          const youtubeLinks = aiResponseText.match(youtubeRegex) || [];
          const spotifyLinks = aiResponseText.match(spotifyRegex) || [];
          const allLinks = [...youtubeLinks, ...spotifyLinks];
          setPreviewLinks(prviewLinks + allLinks.length);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Function to extract music preferences from the user message

  // function extractMusicPreferences(userMessage) {
  //   // Implement your logic to extract genres, artists, and tracks from the user message
  //   // Example:
  //   const genres = ["rock", "pop"];
  //   const artists = ["Artist1", "Artist2"];
  //   const tracks = ["Track1", "Track2"];

  //   return { genres, artists, tracks };
  // }

  // Function to get music recommendations from Spotify
  // async function getSpotifyRecommendations(preferences) {
  //   try {
  //     const response = await fetch("/api/spotify/music-recommendations", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ preferences }),
  //     });

  //     if (!response.ok) throw new Error("Failed to fetch recommendations");

  //     const tracks = await response.json();
  //     return tracks;
  //   } catch (error) {
  //     console.error("Error fetching recommendations:", error);
  //     throw error;
  //   }
  // }

  const renderSocialMediaPreview = (
    link,
    setIndexClick,
    songValue,
    indexClick,
    setContainerClick,
  ) => {
    if (typeof link === "string") {
      if (link.includes("open.spotify.com")) {
        return (
          <SpotifyLinkPreviewer
            link={link}
            setIndexClick={setIndexClick}
            songValue={songValue}
            indexClick={indexClick}
            setContainerClick={setContainerClick}
          />
        );
      } else if (link.includes("youtube.com")) {
        return <YoutubeChatPreview link={link} />;
      } else {
        return <div className="unsupported-link">Unsupported link type.</div>;
      }
    } else {
      console.error("The link is not a string:", link);
      return <div className="unsupported-link">Invalid link format.</div>;
    }
  };

  // const renderSocialMediaPreview = (link) => {
  //   console.log({ link });
  //   if (link.includes("open.spotify.com")) {
  //     return <SpotifyLinkPreviewer link={link} />;
  //   } else if (link.includes("youtube.com")) {
  //     return <YoutubeChatPreview link={link} />;
  //   } else {
  //     return <div className="unsupported-link">Unsupported link type.</div>;
  //   }
  // };

  // const updateUserSongPreference = async (
  //   eventId,
  //   chatTranscriptId,
  //   chatUrl,
  //   chatMessage
  // ) => {
  //   const data = { eventId, url: chatUrl, chatTranscriptId };

  //   const res = await fetch(
  //     `${PORT_BACKEND_URL_SRC}/update-user-song-prefence`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem("authKey"),
  //       },
  //       body: JSON.stringify(data),
  //     }
  //   );

  //   if (res.ok) handleSendMessage(chatMessage);
  // };

  const updateUserSongPreference = async (
    eventId,
    chatTranscriptId,
    chatUrl,
    chatMessage
  ) => {
    try {
      const data = { eventId, url: chatUrl, chatTranscriptId };
  
      const res = await fetch(
        `${PORT_BACKEND_URL_SRC}/update-user-song-prefence`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authKey"),
          },
          body: JSON.stringify(data),
        }
      );
  
      if (!res.ok) throw new Error("Failed to update song preference");
  
      handleSendMessage(chatMessage);
    } catch (error) {
      console.error("Error updating user song preference:", error);
      window.location.reload();
    }
  };

  const [checkedSongs, setCheckedSongs] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [LikeMessage, setLikeMessage] = useState("");
  const [expressionIndex, setExpressionIndex] = useState(0);
  const [messageLinks, setMessageLinks] = useState({});
  const [expressionArr, setExpressionArr] = useState("");

  const startRandomExpressions = () => {
    // const expressions = ['dance', 'happy', 'sad', 'bored', 'excited', 'surprised', 'angry'];
    // const expressions = ["dance", "happy", "sad", "bored", "surprised"];
    const blendShapes = [
      "eyeWideLeft",
      "mouthUpperUpLeft",
      "jawOpen",
      "mouthClose",
    ];

    // Function to get the next expression in the loop
    const getNextExpression = () => {
      const expression = expressionArr[expressionIndex]; // Increment the index and reset to 0 if it exceeds the last index
      // console.log("expressionIndex Before :", expressionIndex);
      // console.log("expression", expression);
      let upDatedValue = (expressionIndex + 1) % expressionArr.length;
      setExpressionIndex(upDatedValue);
      // console.log("expressionIndex After : ", expressionIndex);
      return expression;
    };
    const randomShape = () =>
      blendShapes[Math.floor(Math.random() * blendShapes.length)];

    // Function to trigger the random action
    const selectedExpression = getNextExpression();
    const selectedShape = randomShape();
    setActArray({
      eventExpression: selectedExpression,
      eventName: selectedShape,
    });
  };
  const handleCheckboxChange = (index, el) => {
    // console.log("CheckDisable1handleCheckboxChange", checkDisable);
    // console.log("index------>", index);
    // console.log("el------>", el);
    if (!songArrForSoundEffect.includes(el.songValue)) {
      // console.log("el.songValue", el.songValue);
      playSelectionSound();
    }
    startRandomExpressions();
    setSelectedIndex(index);
    // playSelectionSound();

    setLikeMessage(`Yes, I liked it : ${el?.songName} (${el?.songLink}) `);
    // handleSendMessage(`Yes, I liked it : ${el?.songName} (${el?.songLink})`);

    const chatMessage = `Yes, I liked it : ${el?.songName} (${el?.songLink})`;
    const chatUrl = el?.songLink || "";

    setCheckedSongs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    updateUserSongPreference(eventId, chatTranscriptId, chatUrl, chatMessage);
    setCheckDisable(true);
    setSongArrForSoundEffect((preview) => [...preview, el.songValue]);
  };

  // console.log({ LikeMessage });
  // console.log("CheckDisable1", checkDisable);
  // console.log("indexClickindexClick", indexClick);

  // const returnSongLink = (index) => {
  //   console.log("messageLinks at returnSongLink:", messageLinks);
  //   console.log("Checking for link at index:", index);  // Log the index to see if it's correct

  //   // Ensure index is a number (or match how it's stored)
  //   if (typeof index !== 'number') {
  //     console.warn(`Invalid index type: ${typeof index}. Expected a number.`);
  //     return null;
  //   }

  //   // Try to access the value
  //   const songLink = messageLinks[index];
  //   console.log("Song link found:", songLink);
  //   return songLink;
  // };

  // const returnSongLink = (index) => {
  //   if (!messageLinks || !messageLinks[index]) {
  //     console.warn(`No link found for index: ${index}`);
  //     return null;  // Or handle as needed
  //   }
  //   console.log("messageLinks at returnSongLink:", messageLinks);
  //   return messageLinks[index];
  // };
  console.log("previewLinks$$$$$", prviewLinks);
  console.log("✅ previewLinks just updated:", prviewLinks);
  // console.log("spotifyRegex");

  const calculateOpacityById = (id, messageType) => {
    console.log("messageType", messageType)
    const item = containerClick.find(item => item.id === id);


    if (responseLoading && messageType !== "user") {
      return 0.5; 
    }

  
    if ((item && item.checkboxClicked === true) && (item && item.songClicked === true)) {
      return 0.5; // Full opacity if the item is not played
    }
    
  
    // return 1; // Reduced opacity for played items when more than 2 items are selected
  };

  console.log("containerClick", containerClick)

  return (
    <>
      <audio ref={selectionSound} src="/songs/start-spark-sound-effect.mp3" />
      {firstTime && <ChatExperiencePopup setFirstTime={setFirstTime} />}

      <div className="user-chat-experience-container">
        {/* <div className="w-full z-50">
          <div className="bg-black text-center text-white py-2 text-sm z-50 dark:bg-gray-600">This model is made by ai model <a className='text-blue-500 hover:underline' target='_blank' href='https://sketchfab.com/3d-models/blu-anime-girl-character-c75ab6b43e1e49da952361ee6b36f0a4'>link</a>, if you would like to submit your model to be part of the website experience contact bbfunai4u@gmail.com</div>
        </div> */}

        <TicketPriceProgress
          prviewLinks={prviewLinks}
          initialPrice={eventData ? eventData?.ticketPrice : ""}
          setPreviewPrices={setPreviewPrices}
        />

        <div className="avatar-container">
          {currentAvatar !== "" && (
            <Avatar currentAvatar={currentAvatar} actArray={actArray} />
          )}
        </div>

        <div className="chat-history-container" ref={chatHistoryRef}>
          {chatHistory?.map((message, index) => {
            // console.log("message1", message);

            const extractLinks = (text) => {
              const urlRegex = /(https?:\/\/[^\s)]+)/g;
              return text.match(urlRegex) || [];
            };

            const extractSongs = (dataString) => {
              const regex =
                /(?:\d+\.\s*|:\s*)?([\w\s\-'!&]+)\s*\((https:\/\/open\.spotify\.com\/artist\/[^\)]+)\)/g;
              const extractedSongs = [];
              let match;

              while ((match = regex.exec(dataString)) !== null) {
                extractedSongs.push({
                  songName: match[1],
                  songLink: match[2],
                  // songValue: globalSongIndex,
                });
              }

              if (extractedSongs.length > 1) {
                extractedSongs.forEach((song, index) => {
                  globalSongIndex++;
                  song.songValue = globalSongIndex;
                });
              }

              return extractedSongs;
            };

            const songsArray = extractSongs(message?.text);
            console.log("songsArray", songsArray);

            // const links = extractLinks(message.text);
            // console.log("songListToData", songListToData);

            const removeLinks = (text) => {
              const urlRegex = /\(?(https?:\/\/[^\s)]+)\)?/g;
              return text?.replace(urlRegex, "").trim().replace(/\s+/g, " ");
            };

            const message1 = removeLinks(message?.text);
            // console.log("@@@@@@@@@@@@@!!!!!!!", message1);
            console.log("settingStateFromuserChatEcperience", indexClick);

            return (
              <>
                <div
                  key={index}
                  className={`mt-4 ${
                    message.type === "UserMessage"
                      ? "flex justify-end gap-2 "
                      : "flex justify-start flex-col gap-2"
                  }`}
                >
                  <div
                    key={index}
                    className={`message ${
                      message.type === "user" ? "user-message" : "bot-message"
                    }`}
                  >
                    {message.type === "user" ? (
                      // message.text
                      message1
                    ) : (
                      <>
                        <TypeAnimation
                          cursor={false}
                          sequence={[
                            removePreviewText(message1),
                            () => {
                              setIsDoneShowing(true);
                            },
                          ]}
                          speed={90}
                        />
                      </>
                    )}

                    <div className="flex flex-col gap-3 mt-3">
                      {songsArray?.map((el, index) => {
                        // const isDisabled = indexClick.includes(el.songValue);
                        const isDisabled = indexClick.some(
                          (item) => item.id === el.songValue
                        );

                        const opacity = calculateOpacityById(el.songValue, message.type);
                        console.log("el.songValue", el.songValue);

                        const getDynamicPairs = (value) => {
                          const pairs = [];
                          // Automatically check adjacent pairs (e.g., 2 ↔ 1, 3 ↔ 4, 7 ↔ 8, etc.)
                          const prev = value - 1;
                          const next = value + 1;

                          // Check if adjacent songs exist in the array
                          if (
                            songsArray.some((song) => song.songValue === prev)
                          ) {
                            pairs.push(prev);
                          }

                          if (
                            songsArray.some((song) => song.songValue === next)
                          ) {
                            pairs.push(next);
                          }

                          return pairs;
                        };

                        // const showTooltip = isDisabled && checkDisable;
                        return (
                          <div
                            key={el.songValue}
                            className={`song-preview-wrapper flex items-center gap-2 md:gap-3 ${opacity === 0.5 && 'pointer-events-none'}`}
                            // style={{ opacity: calculateOpacity(containerClick.length) }}
                            style={{ opacity }}
                            
                          >
                            <div className="spotify-preview-container ">
                              {renderSocialMediaPreview(
                                el?.songLink,
                                setIndexClick,
                                el?.songValue,
                                indexClick,
                                setContainerClick
                              )}
                            </div>

                            {/* {selectedIndex === null || selectedIndex === index ? ( */}

                            <div className="checkbox-wrapper">
                              {/* Only render if songValue >= indexClick and message.type !== 'user' */}
                              {message.type !== "user" && (
                                <div className="relative">
                                  <div
                                    onClick={() => {
                                      setCheckDisable(false);
                                      const relatedSongs = getDynamicPairs(
                                        el?.songValue
                                      );
                                      const newSongs = [
                                        el?.songValue,
                                        ...relatedSongs,
                                      ];
                                      // setIndexClick((prevSelected) => {
                                      //   const exists = prevSelected.find(
                                      //     (item) => item.id === el.songValue
                                      //   );
                                      //   if (exists) return prevSelected; // prevent duplicates
                                      //   return [
                                      //     ...prevSelected,
                                      //     { id: el.songValue, played: false },
                                      //   ];
                                      // });
                                      setIndexClick((prevSelected) => {
                                        const newEntries = newSongs
                                          .filter(
                                            (song) =>
                                              !prevSelected.some(
                                                (item) => item.id === song
                                              )
                                          )
                                          .map((song) => ({
                                            id: song,
                                            played: false,
                                          }));

                                        return [...prevSelected, ...newEntries];
                                      });
                                      // setContainerClick((prevSelected) => {
                                      //   const newEntries = newSongs
                                      //     .filter(
                                      //       (song) =>
                                      //         !prevSelected.some(
                                      //           (item) => item.id === song
                                      //         )
                                      //     )
                                      //     .map((song) => ({
                                      //       id: song,
                                      //       checkboxClicked: false,
                                      //       songClicked: false
                                      //     }));

                                      //   return [...prevSelected, ...newEntries];
                                      // });
                                      // setContainerClick((prevSelected) => {
                                      //   const newEntries = newSongs
                                      //     .filter((song) => !prevSelected.some((item) => item.id === song))
                                      //     .map((song) => ({
                                      //       id: song,
                                      //       songClicked: false,
                                      //       checkboxClicked: false, // Initially false
                                      //     }));
                                      
                                      //   return prevSelected.map((item) =>
                                      //     item.songClicked ? { ...item, checkboxClicked: true } : item
                                      //   ).concat(newEntries);
                                      // });
                                      setContainerClick((prevSelected) => {
                                        const newEntries = newSongs
                                          .filter((song) => !prevSelected.some((item) => item.id === song))
                                          .map((song) => ({
                                            id: song,
                                            songClicked: false,
                                            checkboxClicked: false, // Initially false
                                          }));
                                      
                                        // Identify all valid pairs where both items have songClicked: true
                                        const validPairs = new Set();
                                        for (let i = 0; i < prevSelected.length - 1; i += 2) {
                                          if (prevSelected[i].songClicked && prevSelected[i + 1].songClicked) {
                                            validPairs.add(prevSelected[i].id);
                                            validPairs.add(prevSelected[i + 1].id);
                                          }
                                        }
                                      
                                        return prevSelected
                                          .map((item) =>
                                            validPairs.has(item.id) && item.songClicked
                                              ? { ...item, checkboxClicked: true }
                                              : item
                                          )
                                          .concat(newEntries);
                                      });
                                      
                                      
                                    }}
                                    className={
                                      isDisabled && checkDisable ? "group" : ""
                                    } // Conditional group class
                                  >
                                    {/* {showTooltip && (
                                    <div className="absolute top-[10px] left-[100px] -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md z-10 whitespace-nowrap opacity-0 hover:opacity-100 transition-opacity duration-200">
                                     This song preference is already selected.
                                    </div>
                                  )} */}
                                    <input
                                      id={`_checkbox-${el.songValue}`}
                                      type="checkbox"
                                      checked={selectedSongs.includes(
                                        el.songValue
                                      )}
                                      onChange={(e) => {
                                        // Don’t allow uncheck
                                        if (
                                          selectedSongs.includes(el.songValue)
                                        )
                                          return;

                                        const allPlayed = indexClick.every(
                                          (item) => item.played === true
                                        );
                                        if (!allPlayed) {
                                          setPopupMessage(
                                            "To unlock your selection, please listen to both songs for at least 5 seconds each.This helps ensure you make the right choice!"
                                          );
                                          setShowPopup(true);
                                          return;
                                        }

                                        // Only allow selection ONCE
                                        if (e.target.checked) {
                                          setSelectedSongs((prev) => [
                                            ...prev,
                                            el.songValue,
                                          ]);
                                          handleCheckboxChange(index, el); // Optional callback
                                        }
                                      }}
                                      disabled={
                                        responseLoading ||
                                        (isDisabled && checkDisable) ||
                                        selectedSongs.includes(el.songValue)
                                      }
                                    />

                                    {/* <label
                                      htmlFor={`_checkbox-${el.songValue}`}
                                    > */}
                                    <label
                                      htmlFor={`_checkbox-${el.songValue}`}
                                      style={
                                        selectedSongs.includes(el.songValue)
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                    >
                                      <div className="tick_mark" />
                                      <span className="sparkle sparkle-1">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-2">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-3">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-4">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-5">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-6">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-7">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-8">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-9">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-10">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-11">
                                        ✨
                                      </span>
                                      <span className="sparkle sparkle-12">
                                        ✨
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>

                            {/* ) : null} */}
                          </div>
                        );
                      })}
                    </div>

                    {/* <div className="flex flex-col gap-3 mt-3">
                    {message.text &&
                      []
                        .concat(
                          message.text.match(youtubeRegex) || [],
                          message.text.match(spotifyRegex) || []
                        )
                        .map((newLink, linkIndex) => {
                          console.log({ newLink });
  
                          return (
                            <div key={linkIndex}>
                              {renderSocialMediaPreview(newLink)}
                            </div>
                          );
                        })}
                  </div> */}
                  </div>
                </div>
              </>
            );
          })}

          {showButton && (
            <div className="mt-4 flex justify-center">
              <button
                className="bg-red-500 text-white px-3 py-2 rounded flex items-center gap-3"
                onClick={handleReSendMessage}
              >
                <i className="fa fa-refresh"></i>
                Regenerate
              </button>
            </div>
          )}

          {responseLoading && (
            <div className="flex justify-start flex-col gap-2">
              <div className="message bot-message">
                <LoadingData />
              </div>
            </div>
          )}
          <div ref={bottomRef} />
          <div ref={lastMessageRef} />
        </div>

        <div className="chat-input-container dark:bg-black">
          <Chat
            isDoneShowing={isDoneShowing}
            responseLoading={responseLoading}
            onSendMessage={handleSendMessage}
          />
        </div>

        {/* <button
          className="fixed font-bold right-5 px-6 text-lg py-4 cursor-pointer animated-btn text-white rounded top-12 md:top-12 z-50 !w-fit"
          onClick={handleSkip}
        >
          <span className="text-gray-800 dark:text-white">Skip</span>
        </button> */}
        <button
          className="fixed font-bold right-5 px-6 text-lg py-4 cursor-pointer animated-btn  rounded top-12 md:top-12 z-50 !w-fit"
          onClick={handleSkip}
        >
          <span className="text">Skip</span>
        </button>

        {showPopup && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
            aria-hidden="true"
          >
            <div className="relative w-full max-w-md bg-white dark:bg-[#191a1b] rounded-lg shadow-lg p-4 md:p-5">
              <div className="flex items-center justify-between border-b pb-3 mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  🔒 Listen Before You Choose
                </h3>
              </div>

              <p className="text-sm text-gray-800 dark:text-gray-300 mb-6 text-center">
                {popupMessage}
              </p>

              <div className="flex justify-center">
                <button
                  className="text-white bg-red-600 py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center"
                  onClick={() => setShowPopup(false)}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        )}

        {/*<div className="fixed bg-black text-white rounded w-52 p-3 right-2 bottom-20">This model is made by [name Link], if you would like to submit your model to be part of the website experience contact [staff email link] BB4U</div>*/}
      </div>
    </>
  );
}

export default UserChatExperience;
