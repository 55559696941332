import React from "react";

function SpotifyPreview({ link }) {
  // Transform the link to an embed URL
  const isSpotifyLink = link.includes("open.spotify.com");
  const embedUrl = link.replace("open.spotify.com", "open.spotify.com/embed");

  return isSpotifyLink ?(
    // <iframe
    //   className="spotify-iframe"
    //   title="Spotify Content"
    //   src={embedUrl}
    //   // frameBorder="0"
    //   allowTransparency="true"
    //   allow="encrypted-media"
    // ></iframe>
    <iframe
    src={embedUrl}
    className="w-full h-20 sm:h-24 rounded-lg border-none"
    allow="encrypted-media"
    title="Spotify music preview"
  />
  ): (
    <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline break-words">
      {link}
    </a>
  );
}

export default SpotifyPreview;
