// import React from 'react';
// // import BufferToBase64 from '../../utils/bufferToBase64';
// import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
// import MetaTags from '../metaTags';

// const SharePreviewPopup = ({ event, onClose }) => {
//     if (!event) return null;
//     const shareUrl = `${window.location.origin}/user-dashboard-event-share/${event._id}?redirectTo=/user-chat-experience`;
//     const copyToClipboard = async () => {
//         try {
//             // Set focus to a focusable element (e.g., a button)
//             document.querySelector('button').focus();

//             await navigator.clipboard.writeText(shareUrl);
//             alert('Link copied to clipboard');
//         } catch (error) {
//             console.error('Clipboard write error:', error);
//             alert('Failed to copy the link. Please try again.');
//         }
//     };
//     return (
//         <div className="">
//                <MetaTags
//                 title={event.eventName}
//                 description={event.eventDescription}
//                 image={event.eventImage}
//                 name="Event Share"
//             />
//             <div className="">
//                 <div className="card">
//                     <h2 className='whitespace-nowrap w-full overflow-hidden overflow-ellipsis'>{event.eventName}</h2>
//                     <img src={ PORT_BACKEND_URL_SRC +'/'+ event.eventImage} alt={event.eventName} />
//                     <p className='whitespace-nowrap w-full overflow-hidden overflow-ellipsis'>{event.eventDescription}</p>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                     <button onClick={copyToClipboard} className='py-2 px-4 bg-[#686D76] text-white rounded font-bold mr-2 '>
//                         Copy Link
//                     </button>
//                     <button onClick={onClose} className='py-2 px-4 bg-[#686D76] text-white rounded font-bold'>
//                         Close
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SharePreviewPopup;

import React from "react";
// import MetaTags from "../metaTags";
import { PORT_BACKEND_URL_SRC } from "../../srcLocalPortReferenceBackend";

const SharePreviewPopup = ({ event, onClose, incomingShareInfo = null }) => {
  if (!event) return null;

  const shareUrl = `${window.location.origin}/user-dashboard-event-share/${event._id}?redirectTo=/user-chat-experience`;

  const copyToClipboard = async () => {
    try {
      console.log({ incomingShareInfo });
      const textArea = document.createElement("textarea");
      textArea.value = `https://api.2n1t3.com/api/shared-event-info/${event._id}`;
      // textArea.value = shareUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("Link copied to clipboard");
    } catch (error) {
      console.error("Clipboard write error:", error);
      alert("Failed to copy the link. Please try again.");
    }
  };

  return (
    <div className="">
      {/* <MetaTags
        title={event.eventName || "Event Share"}
        description={event.eventDescription || "Join the event!"}
        image={
          event.eventImage?.startsWith("http")
            ? event.eventImage
            : `${PORT_BACKEND_URL_SRC}/${event.eventImage}`
        }
        name="Event Share"
      /> */}
      <div className="">
        <div className="card">
          <h2 className="whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
            {event.eventName}
          </h2>
          <img
            src={
              event.eventImage?.startsWith("http")
                ? event.eventImage
                : `${PORT_BACKEND_URL_SRC}/${event.eventImage}`
            }
            alt={event.eventName}
          />
          <p className="whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
            {event.eventDescription}
          </p>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={copyToClipboard}
            className="py-2 px-4 bg-[#686D76] text-white rounded font-bold mr-2 "
          >
            Copy Link
          </button>
          <button
            onClick={onClose}
            className="py-2 px-4 bg-[#686D76] text-white rounded font-bold"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharePreviewPopup;
