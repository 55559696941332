// SocialMediaLinks.js
import React from "react";
import SpotifyPreview from "../../previews/spotifyPreview";
import SoundcloudPreview from "../../previews/soundcloudPreview";
import TikTokPreview from "../../previews/tiktokPreview";
import InstagramPreview from "../../previews/instagramPreview";
import "../../../styles/detail.css";
import YoutubePreview from "../../previews/youtubePreview";
import SpotifyLinkPreviewer from "../../previews/spotifyLinkPreviewer";
import YoutubeChatPreview from "../../previews/youtubechatPreview";

function DetailSocialMediaLinks({ links }) {
  console.log("DetailSocialMediaLinks", links)
  const renderSocialMediaPreview = (link) => {
    if (link.includes("spotify.com")) {
      return <SpotifyPreview link={link} />;
    } else if (link.includes("soundcloud.com")) {
      return <SoundcloudPreview link={link} />;
    } else if (link.includes("tiktok.com")) {
      return <TikTokPreview link={link} />;
    } else if (link.includes("instagram.com")) {
      return <InstagramPreview link={link} />;
    } else if (link.includes("youtube.com")) {
      return <YoutubePreview link={link} />;
    } else {
      return <div className="unsupported-link">Unsupported link type.</div>;
    }
  };

  // const renderSocialMediaPreview = (
  //   link
  // ) => {
  //   console.log("type of link", typeof(link));
    
  //   if (typeof link === "string") {
  //     if (link.includes("spotify.com")) {
  //       return (
          // <SpotifyLinkPreviewer
          //   link={link}
          // />
  //       );
  //     } else if (link.includes("youtube.com")) {
  //       return <YoutubeChatPreview link={link} />;
  //     } else {
  //       return <div className="unsupported-link">Unsupported link type.</div>;
  //     }
  //   } else {
  //     console.error("The link is not a string:", link);
  //     return <div className="unsupported-link">Invalid link format.</div>;
  //   }
  // };

  return (
    <div className="social-media-links-container grid grid-cols-1 gap-4 sm:gap-6 md:gap-8 lg:gap-10 xl:gap-1">
      {links?.map((link, index) => (
        <div key={index} className="social-media-preview-card bg-transparent">
          {renderSocialMediaPreview(link)}
        </div>
      ))}
    </div>
  );
}


export default DetailSocialMediaLinks;
